<template>
  <div class="login">
    <div class="login__inner">
      <b-card class="login__card" bg-variant="dark" text-variant="light">
        <b-card-title>Login</b-card-title>
        <b-form @submit.prevent="logIn" class="login__form">
            <b-input v-model="$v.email.$model" type="text" placeholder="Email"></b-input>
            <b-input v-model="$v.password.$model" type="password" placeholder="Password"></b-input>
            <b-button type="submit">Log In</b-button>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import {email, required, minLength} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Login",
  data() {
    return {
      email: '',
      password: '',
    }
  },
  validations: {
    email: {
      type: email,
      required
    },
    password: {
      required,
      minLength: minLength(6)
    }
  },
  watch: {
    error(e) {
      this.$toast.error(e.message)
    },
    user(user) {
      if(user) {
        this.$router.push('/administrator')
        this.$toast.success(`Welcome, ${this.user.email}`)
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'admin/user',
      error: 'admin/error'
    })
  },
  methods: {
    logIn() {
      this.$v.$touch()
      if(this.$v.$invalid) {
        this.$toast.error('Invalid credentials')
      } else {
         this.signIn({email: this.email, password: this.password})
      }
    },
    ...mapActions({
      signIn: 'admin/signIn'
    }),
  }
}
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  background-color: #f1f1f1;
  justify-content: center;
  padding-top: calc(50vh - 180px);
  width: 100vw;
  height: 100vh;
  &__inner {
    width: 360px;
    height: 360px;
  }
  &__card {
    width: 100%;
    height: 100%;;
  }
  &__form {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
  }
}
</style>