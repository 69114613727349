<template>
  <div class="general">
    <b-row>
      <b-col cols="6">
        <h1 class="title">Про нас</h1>
        <b-input class="menu-item" type="text" placeholder="about title" v-model="mAbout.title" />
        <b-textarea class="menu-item" placeholder="Paragraph 1" v-model="mAbout.paragraph1" />
        <b-textarea class="menu-item" placeholder="Paragraph 2" v-model="mAbout.paragraph2" />
      </b-col>
      <b-col cols="6">
        <div class="general__file-about" >
          <img class="general__preview" :src="mAbout.image" alt="">
          <span>
        <input type="file" @change="changeAboutFile" />
        </span>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-button @click="saveAbout" style="margin-top: 50px;" type="submit" variant="success">Зберегти зміни</b-button>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mFirestore, mStorage} from "../../main";

export default {
  name: "About",
  data() {
    return {
      mAbout: {
        title: '',
        paragraph1: '',
        image: '',
        paragraph2: ''
      }
    }
  },
  watch: {
    aboutPage(value) {
      if(value) {
        this.mAbout = {...value}
      }
    }
  },
  mounted() {
    this.getAbout()
  },
  computed: {
    ...mapGetters({
      aboutPage: 'general/about'
    })
  },
  methods: {
    ...mapActions({
      getAbout: 'general/getAbout'
    }),
    changeAboutFile(event) {
      const file = event.target.files[0]
      const oldRef = this.mAbout.image
      mStorage.refFromURL(oldRef).put(file).then(async res => {
        this.mAbout.image = await res.ref.getDownloadURL()
        this.$toast.success('Image successfully updated!')
      }).catch(err => this.$toast.error(err))
    },
    mapData() {
      return {...this.mAbout};
    },
    saveAbout() {
      mFirestore.collection('about').get().then(querySnapshot => {
        querySnapshot.forEach(doc => doc.ref.set(this.mapData()).then(() => {
          this.$toast.success('Зміни збережено!')
        }).catch(err => this.$toast.error(err)))
      })
    }
  },
}
</script>

<style scoped>

</style>