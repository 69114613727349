<template>
  <div class="general">
    <b-row>
      <h1 class="title">Волонтерство</h1>
        <b-col cols="6">
          <b-input type="text" class="menu-item" placeholder="Title" v-model="mSupport.title" />
          <b-input type="text" class="menu-item" placeholder="Subtitle" v-model="mSupport.subtitle" />
          <b-textarea type="text" class="menu-item" placeholder="Paragraph1" v-model="mSupport.paragraph1" />
          <b-textarea type="text" class="menu-item" placeholder="Paragraph2" v-model="mSupport.paragraph2" />
          <b-input type="text" class="menu-item" placeholder="Title2" v-model="mSupport.title2" />
        </b-col>
      <b-col cols="6">
        <ul style="color: #110000; overflow-y: scroll; max-height: 175px" v-if="mSupport.list.length">
          <li style="margin-bottom: 4px" v-for="(item, idx) in mSupport.list" :key="idx">{{item}}<b-icon-trash-fill @click="deleteItem(idx)" /></li>
        </ul>
        <b-input type="text" v-model="tempItem" class="menu-item" placeholder="Menu item name here..."></b-input>
        <b-button variant="success" @click="addItem">Add to list</b-button>
      </b-col>
    </b-row>
    <b-row>
      <h1 class="title">Як стати волонтером</h1>
    <b-col cols="6">
      <div>
        <p style="color:#110000;">1</p>
        <b-input type="text" v-model="mSupport.steps.step1.title" class="menu-item" placeholder="title" />
        <b-input type="text" v-model="mSupport.steps.step1.subtitle" class="menu-item" placeholder="subtitle" />
      </div>
      <div>
        <p style="color:#110000;">2</p>
        <b-input type="text" v-model="mSupport.steps.step2.title" class="menu-item" placeholder="title" />
        <b-input type="text" v-model="mSupport.steps.step2.subtitle" class="menu-item" placeholder="subtitle" />
      </div>
      <div>
        <p style="color:#110000;">3</p>
        <b-input type="text" v-model="mSupport.steps.step3.title" class="menu-item" placeholder="title" />
        <b-input type="text" v-model="mSupport.steps.step3.subtitle" class="menu-item" placeholder="subtitle" />
      </div>
      <div>
        <p style="color:#110000;">4</p>
        <b-input type="text" v-model="mSupport.steps.step4.title" class="menu-item" placeholder="title" />
        <b-input type="text" v-model="mSupport.steps.step4.subtitle" class="menu-item" placeholder="subtitle" />
      </div>
    </b-col>
      <b-col cols="6">
        <p style="color:#110000;">Форма</p>
        <b-input class="menu-item" type="text" placeholder="support title" v-model="mSupport.support.title" />
        <b-input class="menu-item" type="text" placeholder="support form title" v-model="mSupport.support.formTitle" />
        <b-input class="menu-item" type="text"
                 placeholder="support form subtitle" v-model="mSupport.support.formSubtitle" />
        <b-input class="menu-item" type="text" placeholder="input 1" v-model="mSupport.support.formLabels.label1" />
        <b-input class="menu-item" type="text" placeholder="input 2" v-model="mSupport.support.formLabels.label2" />
        <b-input class="menu-item" type="text" placeholder="button text" v-model="mSupport.support.submitText" />
        <wysiwyg style="color: #110000;" v-model="mSupport.support.text" />
      </b-col>
    </b-row>
    <b-row>
      <b-button @click="saveSupport" style="margin-top: 50px;" type="submit" variant="success">Зберегти зміни</b-button>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mFirestore} from "../../main";

export default {
  name: "Support",
  data() {
    return {
      tempItem: '',
      mSupport: {
        title: '',
        subtitle: '',
        paragraph1: '',
        list: [],
        paragraph2: '',
        title2: '',
        steps: {
          step1: {
            title: '',
            subtitle: '',
            index: 1
          },
          step2: {
            title: '',
            subtitle: '',
            index: 2,
          },
          step3: {
            title: '',
            subtitle: '',
            index: 3,
          },
          step4: {
            title: '',
            subtitle: '',
            index: 4
          }
        },
        support: {
          title: '',
          formTitle: '',
          formSubtitle: '',
          formLabels: {
            label1: '',
            label2: ''
          },
          submitText: '',
          text: ''
        },
      }
    }
  },
  watch: {
    support(value) {
      if(value) {
        this.mSupport = {
          ...value,
          list: [...value.list],
          steps: {
            step1: {...value.steps.step1},
            step2: {...value.steps.step2},
            step3: {...value.steps.step3},
            step4: {...value.steps.step4}
          },
          support: {...value.support, formLabels: {label1: value.support.formLabels[0],
              label2: value.support.formLabels[1]}}
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getSupport: 'general/getSupport'
    }),
    deleteItem(idx) {
      this.mSupport.list.splice(idx,1)
    },
    addItem() {
      if(!this.tempItem || !this.tempItem.length) {
        return
      }
      this.mSupport.list.push(this.tempItem)
      this.tempItem = ''
    },
    mapData() {
      const resultObj = {
        ...this.mSupport,
        list: [...this.mSupport.list],
        steps: {
          step1: {...this.mSupport.steps.step1},
          step2: {...this.mSupport.steps.step2},
          step3: {...this.mSupport.steps.step3},
          step4: {...this.mSupport.steps.step4}
        }
      }
      const mFormLabels = []
      Object.values(this.mSupport.support.formLabels).forEach( val => {
        mFormLabels.push(val)
      })
      resultObj.support = {...this.mSupport.support, formLabels: mFormLabels}
      return resultObj
    },
    saveSupport() {
      mFirestore.collection('support').get().then(querySnapshot => {
        querySnapshot.forEach(doc => doc.ref.set(this.mapData()).then(() => {
          this.$toast.success('Зміни збережено!')
        }).catch(err => this.$toast.error(err)))
      })
    }
  },
  computed: {
    ...mapGetters({
      support: 'general/support'
    })
  },
  mounted() {
    this.getSupport()
  }
}
</script>

<style scoped>

</style>