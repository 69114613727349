<template>
<main>
<div class="new-detail container">
  <div class="new-detail__title" :style="`background-image: url('${content.image}');`">
    <h1 class="new-detail__text">{{content.title}}</h1>
  </div>
  <div class="new-detail__content">
    <p class="new-detail__new-content" v-html="content.content">
    </p>
  </div>
</div>
</main>
</template>

<script>
import {mapActions, mapMutations, mapGetters} from "vuex";

export default {
  name: "NewsDetailPage",
  data() {
    return {
      content: {}
    }
  },
  methods: {
    ...mapActions({
      getNewsItem: 'general/getNewsItem'
    }),
    ...mapMutations({
      setNewsItem: 'general/setNewsItem'
    })
  },
  computed: {
    ...mapGetters({
      newsItem: 'general/newsItem'
    })
  },
  watch: {
    newsItem(value) {
      this.content = {...value}
    }
  },
  mounted() {
    this.getNewsItem(this.$route.params.uid)
  },
  destroyed() {
    this.setNewsItem(null)
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/style/colors';
.new-detail {
  &__title {
    position: relative;
    margin-top: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-width: 100%;
    width: auto;
    max-height: 360px;
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__text {
    font-family: "Century Gothic", serif;
    font-size: 45px;
    line-height: 55px;
    text-align: center;
    max-width: 680px;
    align-self: center;
    @media(max-width: 1080px) {
      margin-top: 65px;
    }
    @media(max-width: 650px) {
      font-size: 25px;
      line-height: 35px;
    }
  }
  &__content {
    padding-top: 45px;
  }
  &__new-content {
    font-family: "Century Gothic", serif;
    font-size: 18px;
    line-height: 25px;
    color: $text-grey;
    padding-bottom: 80px;
  }
}

</style>