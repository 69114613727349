<template>
  <main>
    <div class="project-detail container">
      <div class="project-detail__title" :style="`background: url('${content.image}') no-repeat;`">
        <h1 class="project-detail__text">{{content.title}}</h1>
      </div>
      <div class="project-detail__content">
        <p class="project-detail__project-content" v-html="content.content"></p>
      </div>
    </div>
    <Help :content="general.support" />
  </main>
</template>

<script>
import Help from "../components/Help";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  name: "ProjectsDetailPage",
  components: {Help},
  data() {
    return {
      content: {}
    }
  },
  methods: {
    ...mapActions({
      getProjectsItem: 'general/getProjectsItem'
    }),
    ...mapMutations({
      setProjectsItem: 'general/setProjectsItem'
    })
  },
  computed: {
    ...mapGetters({
      general: 'general/general',
      projectsItem: 'general/projectsItem'
    })
  },
  watch: {
    projectsItem(value) {
      this.content = {...value}
    }
  },
  mounted() {
    this.getProjectsItem(this.$route.params.uid)
  },
  destroyed() {
    this.setProjectsItem(null)
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/style/colors';
.project-detail {
  @media(max-width: 1080px) {
    margin-top: 65px;
  }
  &__title {
    position: relative;
    margin-top: 100px;
    background-repeat:  no-repeat;
    background-size: cover;
    width: 100%;
    max-height: 360px;
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__text {
    font-family: "Century Gothic", serif;
    font-size: 45px;
    line-height: 55px;
    text-align: center;
    max-width: 680px;
    align-self: center;
    @media(max-width: 650px) {
      font-size: 25px;
      line-height: 35px;
    }
  }
  &__content {
    padding-top: 45px;
  }
  &__project-content {
    font-family: "Century Gothic", serif;
    font-size: 18px;
    line-height: 25px;
    color: $text-grey;
    padding-bottom: 80px;
  }
}

</style>