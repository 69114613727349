<template>
<div class="general">
  <b-row>
    <b-input class="menu-item" type="text" placeholder="Title" v-model="item.title"/>
    <b-input class="menu-item" type="text" placeholder="Subtitle" v-model="item.subtitle"/>
    <p style="color: #110000;">Banner</p>
    <input type="file" @change="changeBanner">
    <img style="width: 500px; height: 200px" :src="item.image" alt="" />
    <p style="color: #110000;">Preview</p>
    <input type="file" @change="changePreview">
    <img style="width: 300px; height: 200px" :src="item.previewImage" alt="" />
    <wysiwyg style="color: #110000;" v-model="item.content" />
  </b-row>
  <b-row>
    <b-button variant="success" @click="updateItem" >Змінити новину</b-button>
    <b-button style="margin-top: 50px" variant="danger" @click="deleteItem" >Видалити новину</b-button>
  </b-row>
</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {mFirestore, mStorage} from "../../main";

export default {
  name: "NewsDetail",
  data() {
    return {
      item: {

      }
    }
  },
  methods: {
    changeBanner(event) {
      const file = event.target.files[0]
      if(!file) {
        return
      }
      mStorage.refFromURL(this.item.image).put(file).then( async res => {
        this.item.image = await res.ref.getDownloadURL()
      }).then(() => this.$toast.success('Image successfully updated!'))
        .catch(err => this.$toast.error(err))
    },
    changePreview(event) {
      const file = event.target.files[0]
      if(!file) {
        return
      }
      mStorage.refFromURL(this.item.previewImage).put(file).then( async res => {
        this.item.previewImage = await res.ref.getDownloadURL()
      }).then(() => this.$toast.success('Image successfully updated!'))
        .catch(err => this.$toast.error(err))
    },
    updateItem() {
        mFirestore.collection('news').where('uid', "==", this.$route.params.uid).get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => doc.ref.set({...this.item}))
        this.$router.push('/administrator/news')
        this.$toast.success('News successfully updated!')
      }).catch(err => this.$toast.error(err))
    },
    deleteItem() {
      mStorage.refFromURL(this.item.image).delete().then(() => {
        mStorage.refFromURL(this.item.previewImage).delete().then(() => {
          mFirestore.collection('news').where('uid', "==", this.$route.params.uid).get()
              .then(querySnapshot => {
                querySnapshot.forEach(doc => doc.ref.delete())
                this.$router.push('/administrator/news')
                this.$toast.success('New successfully deleted!')
              }).catch(err => this.$toast.error(err))
        }).catch(err => this.$toast.error(err))
      }).catch(err => this.$toast.error(err))
    },
    ...mapActions({
      getNewsItem: 'general/getNewsItem'
    }),
    ...mapMutations({
      setNewsItem: 'general/setNewsItem'
    })
  },
  watch: {
    newsItem(value) {
      this.item = {...value}
    }
  },
  computed: {
    ...mapGetters({
      newsItem: 'general/newsItem'
    })
  },
  mounted() {
      this.getNewsItem(this.$route.params.uid)
  },
  destroyed() {
    this.setNewsItem(null)
  }
}
</script>

<style scoped>

</style>