<template>
<main>
  <div id="our-news">
    <News :news="news" title="Новини"/>
  </div>
</main>
</template>

<script>
import News from "../components/News";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NewsPage",
  components: {
    News
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions({
      getNews: 'general/getNews'
    })
  },
  computed: {
    ...mapGetters({
      news: 'general/news'
    })
  },
  mounted() {
    this.getNews()
  }
}
</script>

<style lang="scss" scoped>
#our-news{
  @media(max-width: 1080px) {
    margin-top: 65px;
  }
}
</style>