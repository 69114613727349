<template>
 <div>
   <Header />
   <Slider />
   <slot/>
   <Footer />
   <div  class="top-arrow" v-if="showTopArrow" @click="scrollTop">
    <img class="top-arrow__img" src="../assets/images/arrow.png" alt="^">
    <img class="top-arrow__bg" src="../assets/images/arrow-bg.png" alt="">
   </div>
 </div>
</template>

<script>
import Header from "../components/Header";
import Slider from "../components/Slider";
import Footer from "../components/Footer";
import {mapActions} from 'vuex'

export default {
  name: 'MainLayout',
  components: {
    Footer,
    Header,
    Slider
  },
  data() {
    return {
      showTopArrow: false
    }
  },
  methods: {
    handleScroll(event) {
        this.showTopArrow = event.target.documentElement.scrollTop > 715;
    },
    scrollTop() {
      window.scroll({
        left: 0,
        top: 0,
        behavior: "smooth"
      })
    },
    ...mapActions({
      getGeneral: 'general/getGeneral'
    })
  },
  mounted() {
    this.getGeneral()
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
},
destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
}
}
</script>

<style lang="scss" scoped>
.top-arrow {
  position: fixed;
  right: 30px;
  bottom: 25px;
  z-index: 9998;
  object-fit: contain;
  cursor: pointer;
  &__img {
    position: absolute;
    bottom: 14px;
    left:11px;
    width: 50px;
  }
  &__bg {
    z-index: 9997;
    animation: pulse 2s infinite;
    @keyframes pulse {
      0%{
        transform: scale(1);
        opacity: 1;
      }
      //25% {
      //  transform: scale(1.1);
      //  opacity: 0.5;
      //}
      100% {
        transform: scale(1.2);
        opacity: 0;
      }
    }
  }
}
</style>