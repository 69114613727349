<template>
<div class="step">
  <div class="step__index">
    {{step.index}}
  </div>
  <p class="step__title">{{step.title}}</p>
  <p class="step__subtitle">{{step.subtitle}}</p>
</div>
</template>

<script>
export default {
  name: "Step",
  props: {
    step: {
      type: Object,
      required: true
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/style/colors';
.step{
  display: flex;
  justify-content: start;
  flex-direction: column;
  text-align: center;
  font-family: "Century Gothic", serif;
  max-width: 260px;
  min-height: 260px;
  &__index {
    font-size: 30px;
    line-height: 25px;
    font-weight: bold;
    color: $white-color;
    background-color: $text-blue;
    border-radius: 50%;
    text-align: center;
    padding-top: 20px;
    width: 65px;
    height: 65px;
    align-self: center;
  }

  &__title {
    font-size: 25px;
    padding-top: 20px;
    line-height: 30px;
    font-weight: bold;
    color: $darkgrey-color;
  }
  &__subtitle {
    font-size: 18px;
    line-height: 28px;
    color: $text-grey;
  }
}
</style>