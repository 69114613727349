<template>
<div class="step-list">
 <step :step="steps.step1"/>
 <step :step="steps.step2"/>
 <step :step="steps.step3"/>
 <step :step="steps.step4"/>
</div>
</template>

<script>
import Step from "./Step";
export default {
  name: "StepList",
  components: {Step},
  data() {
    return {
    }
  },
  props: {
    steps: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .step-list {
    padding-top: 50px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-bottom: 80px;
  }
</style>