<template>
  <main>
    <div id="partnership" v-if="partnership">
    <div class="partnership-title row">
      <Title :text="partnership.title" tag="h1"/>
    </div>
    <h2 class="partnership-subtitle">{{partnership.subtitle}}</h2>
    <div class="partnership container">
      <h3 class="partnership__title">{{partnership.paragraph1}}
      </h3>

      <ul class="partnership__list">
        <li v-for="(item, idx) in partnership.list" :key="idx">{{item}}</li>
      </ul>

      <h4 class="partnership__subtitle">{{partnership.paragraph2}}
      </h4>
      <div class="partnership__join">
        <Title tag="h5" :text="partnership.title2"/>
      </div>
      <div class="row">
        <StepList :steps="partnership.steps"/>
      </div>
      <div class="partnership__needs-title">
      <Title tag="h6" :text="partnership.title3" />
      </div>
      <div class="partnership__needs">
        <div class="partnership__item">
          <img class="item-image" src="../assets/images/coins.png" alt="">
          <p class="text-grey">{{partnership.needs.need1}}</p>
        </div>
        <div class="partnership__item">
          <img class="item-image" src="../assets/images/oil.png" alt="">
          <p class="text-grey">{{partnership.needs.need2}}</p>
        </div>
        <div class="partnership__item">
          <img class="item-image" src="../assets/images/project.png" alt="">
          <p class="text-grey">{{partnership.needs.need3}}</p>
        </div>
        <div class="partnership__item">
          <img class="item-image" src="../assets/images/meds.png" alt="">
          <p class="text-grey">{{partnership.needs.need4}}</p>
        </div>
        <div class="partnership__item">
          <img class="item-image" src="../assets/images/cloth.png" alt="">
          <p class="text-grey">{{partnership.needs.need5}}</p>
        </div>
        <div class="partnership__item">
          <img class="item-image" src="../assets/images/equipment.png" alt="">
          <p class="text-grey">{{partnership.needs.need6}}</p>
        </div>
        <div class="partnership__item">
          <img class="item-image" src="../assets/images/skills.png" alt="">
          <p class="text-grey">{{partnership.needs.need7}}</p>
        </div>
        <div class="partnership__item">
          <img class="item-image" src="../assets/images/grants.png" alt="">
          <p class="text-grey">{{partnership.needs.need8}}</p>
        </div>
      </div>
      <Title class="partnership__info-title" tag="h6" :text="partnership.title4" />
      <div class="partnership__help">
        <div class="partnership-help__item" v-for="(item, idx) in partnership.infoHelp" :key="idx">
          <p>{{ item }}</p>
          <div class="background-1"></div>
          <div class="background-2"></div>
        </div>
      </div>
    </div>
    <Help :content="partnership.support" />
    <Title tag="h6" class="partnership__projects-title" :text="partnership.title5" />
    <Projects v-if="projects && projects.length" :projects="projects.slice(0, 3)" :title="false" :btn="true"/>
    </div>
  </main>
</template>

<script>
import Help from "../components/Help";
import Title from "../components/Title";
import StepList from "../components/StepList";
import Projects from "../components/Projects";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PartnershipPage",
  components: {Projects, StepList, Title, Help},
  data() {
    return {
    }
  },
  methods: {
    ...mapActions({
      getPartnership: 'general/getPartnership',
      getProjects: 'general/getProjects'
    }),
  },
  computed: {
    ...mapGetters({
      partnership: 'general/partnership',
      projects: 'general/projects'
    })
  },
  created() {
    this.getPartnership()
    this.getProjects()
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/style/colors';

#partnership {
  @media(max-width: 1080px) {
    margin-top: 65px;
  }
}
.partnership-title {
  @media(max-width: 650px) {
    transform: scale(0.6);
  }
}

.partnership-subtitle {
  font-family: "Bad Script", serif;
  color: $text-grey;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
}

.partnership {
  &__title {
    font-family: "Century Gothic", serif;
    font-size: 18px;
    line-height: 25px;
    color: $text-grey;
    font-weight: normal;
    padding-top: 90px;
  }
  &__needs-title {
    @media(max-width: 650px) {
      transform: scale(0.6);
    }
  }
  &__info-title {
    @media(max-width: 650px) {
      transform: scale(0.6);
    }
  }
  &__projects-title {
    @media(max-width: 650px) {
      transform: scale(0.6);
    }
  }
  &__list {
    font-family: "Century Gothic", serif;
    padding-top: 35px;
    font-size: 18px;
    line-height: 25px;
    color: $text-grey;
    //max-height: 420px;
    padding-left: 15px;
    column-count: 3;
    @media(max-width: 1180px) {
     column-count: 2;
    }
    @media(max-width: 750px) {
      column-count: 1;
    }
  }

  &__list li {
    list-style-image: url(../assets/images/list-marker.png);
    margin-bottom: 10px;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  //&__list li::before {
  //  content: url(../assets/images/list-marker.png);
  //  margin-right: 20px;
  //  max-width: 10px;
  //  max-height: 10px;
  //  width: 100%;
  //  height: 100%;
  //}

  &__subtitle {
    font-family: "Century Gothic", serif;
    font-size: 18px;
    line-height: 25px;
    color: $text-grey;
    padding-top: 35px;
  }

  &__join {
    margin-top: 80px;
    @media(max-width: 650px) {
      margin-top: 20px;
      transform: scale(0.6);
    }
  }
  &__needs {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 90px;
    padding-top: 80px;
    margin-bottom: 80px;
    @media(max-width: 650px) {
      justify-content: center;
    }
  }
  &__item {
    max-height: 125px;
    max-width: 215px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  &__help {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 90px;
    margin-bottom: 90px;
  }
}

.partnership-help__item {
    width: 260px;
    height: 260px;
    border: 2px dashed $text-blue;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 80px;
}

.partnership-help__item p {
  text-align: center;
  font-family: "Century Gothic", serif;
  font-size: 18px;
  line-height: 28px;
  color: $darkgrey-color;
  padding: 20px;
}

.item-image {
  width: 56px;
  height: 56px;
  align-self: center;
}
.text-grey {
  font-family: "Century Gothic", serif;
  font-size: 18px;
  line-height: 38px;
  color: $darkgrey-color;
  text-align: center;
}
.background-1 {
  background-color: $text-blue;
  opacity: 0.1;
  position: absolute;
  content: "";
  width: 225px;
  height: 225px;
  top: calc(50% - 112.5px);
  left: calc(50% - 112.5px);
  border-radius: 50%;
  animation: pulse 2s infinite;
  @keyframes pulse {
    0%{
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}

.background-2 {
  background-color: $text-blue;
  opacity: 0.1;
  position: absolute;
  content: "";
  width: 105px;
  height: 105px;
  border-radius: 50%;
  top: calc(50% - 52.5px);
  left: calc(50% - 52.5px);
}
.background-2:hover {
  transform: scale(1.3);
  transition: .5s;
}

</style>