<template>
<main>
  <div id="our-projects">
    <Projects :projects="projects" :title="true" :btn="false"/>
  </div>
</main>
</template>

<script>
import Projects from "../components/Projects";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "ProjectsPage",
  components: {Projects},
  data() {
    return {}
  },
  methods: {
    ...mapActions({
      getProjects: 'general/getProjects'
    })
  },
  computed: {
    ...mapGetters({
      projects: 'general/projects'
    })
  },
  mounted() {
    this.getProjects()
  }
}
</script>

<style lang="scss" scoped>
#our-projects {
  @media(max-width: 1080px) {
    margin-top: 65px;
  }
}
</style>