<template>
  <div>
    <div class="admin-header" :style="showSidebar ? 'padding-left: 20%' : ''">
      <div>
        <b-navbar toggleable="md" type="dark" variant="dark">
          <b-button @click="toggleSidebar" variant="dark" class="admin-header__toggle"><b-icon icon="list" variant="light"  /></b-button>
          <b-navbar-brand class="admin-header__text">AdminPanel</b-navbar-brand>
          <b-button @click="logOut" variant="dark" right><b-icon-person-x-fill /></b-button>
        </b-navbar>
      </div>
    </div>
    <div>
      <Menu/>
    </div>
    <div :style="showSidebar ? 'padding-left:20%;' : ''" style="padding-top: 50px">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import Menu from "../../components/admin/Menu";

export default {
  name: "Index",
  components: {Menu},
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      user: 'admin/user',
      showSidebar: 'admin/showSidebar'
    })
  },
  methods: {
    logOut() {
      this.signOut()
      this.$router.push('/login')
    },
    ...mapActions({
      signIn: 'admin/signIn',
      signOut: 'admin/signOut'
    }),
    ...mapMutations({
      setUser: 'admin/setUser',
      toggleSidebar: 'admin/toggleSidebar'
    }),
  },
}
</script>

<style lang="scss" scoped>
.admin-header {
  z-index: 9999;
  position: fixed;
  width: 100%;
  &__text {
    padding: 20px;
  }
  &__toggle {
    margin-left: 20px;
  }
}
</style>
