<template>
  <div class="general">
    <b-row>
      <h1 class="title">Волонтерство</h1>
      <b-col cols="6">
        <b-input type="text" class="menu-item" placeholder="Title" v-model="mPartnership.title" />
        <b-input type="text" class="menu-item" placeholder="Subtitle" v-model="mPartnership.subtitle" />
        <b-textarea type="text" class="menu-item" placeholder="Paragraph1" v-model="mPartnership.paragraph1" />
        <b-textarea type="text" class="menu-item" placeholder="Paragraph2" v-model="mPartnership.paragraph2" />
        <b-input type="text" class="menu-item" placeholder="Title2" v-model="mPartnership.title2" />
      </b-col>
      <b-col cols="6">
        <ul style="color: #110000; overflow-y: scroll; max-height: 175px" v-if="mPartnership.list.length">
          <li style="margin-bottom: 4px" v-for="(item, idx) in mPartnership.list" :key="idx">{{item}}<b-icon-trash-fill @click="deleteItem(idx)" /></li>
        </ul>
        <b-input type="text" v-model="tempItem" class="menu-item" placeholder="Menu item name here..."></b-input>
        <b-button variant="success" @click="addItem">Add to list</b-button>
      </b-col>
    </b-row>
    <b-row>
      <h1 class="title">Як стати волонтером</h1>
      <b-col cols="6">
        <div>
          <p style="color:#110000;">1</p>
          <b-input type="text" v-model="mPartnership.steps.step1.title" class="menu-item" placeholder="title" />
          <b-input type="text" v-model="mPartnership.steps.step1.subtitle" class="menu-item" placeholder="subtitle" />
        </div>
        <div>
          <p style="color:#110000;">2</p>
          <b-input type="text" v-model="mPartnership.steps.step2.title" class="menu-item" placeholder="title" />
          <b-input type="text" v-model="mPartnership.steps.step2.subtitle" class="menu-item" placeholder="subtitle" />
        </div>
        <div>
          <p style="color:#110000;">3</p>
          <b-input type="text" v-model="mPartnership.steps.step3.title" class="menu-item" placeholder="title" />
          <b-input type="text" v-model="mPartnership.steps.step3.subtitle" class="menu-item" placeholder="subtitle" />
        </div>
        <div>
          <p style="color:#110000;">4</p>
          <b-input type="text" v-model="mPartnership.steps.step4.title" class="menu-item" placeholder="title" />
          <b-input type="text" v-model="mPartnership.steps.step4.subtitle" class="menu-item" placeholder="subtitle" />
        </div>
      </b-col>
      <b-col cols="6">
        <p style="color:#110000;">Форма</p>
        <b-input class="menu-item" type="text" placeholder="support title" v-model="mPartnership.support.title" />
        <b-input class="menu-item" type="text" placeholder="support form title" v-model="mPartnership.support.formTitle" />
        <b-input class="menu-item" type="text"
                 placeholder="support form subtitle" v-model="mPartnership.support.formSubtitle" />
        <b-input class="menu-item" type="text" placeholder="input 1" v-model="mPartnership.support.formLabels.label1" />
        <b-input class="menu-item" type="text" placeholder="input 2" v-model="mPartnership.support.formLabels.label2" />
        <b-input class="menu-item" type="text" placeholder="button text" v-model="mPartnership.support.submitText" />
        <wysiwyg style="color: #110000;" v-model="mPartnership.support.text" />
      </b-col>
    </b-row>
    <h1 class="title">Способи підтримки</h1>
    <p style="color: #110000">Заголовок</p>
    <b-input class="menu-item" type="text" placeholder="title" v-model="mPartnership.title3" />
    <b-input class="menu-item" type="text"  placeholder="1"  v-model="mPartnership.needs.need1"/>
    <b-input class="menu-item" type="text"  placeholder="2"  v-model="mPartnership.needs.need2"/>
    <b-input class="menu-item" type="text"  placeholder="3"  v-model="mPartnership.needs.need3"/>
    <b-input class="menu-item" type="text"  placeholder="4"  v-model="mPartnership.needs.need4"/>
    <b-input class="menu-item" type="text"  placeholder="5"  v-model="mPartnership.needs.need5"/>
    <b-input class="menu-item" type="text"  placeholder="6" v-model="mPartnership.needs.need6"/>
    <b-input class="menu-item" type="text"  placeholder="7" v-model="mPartnership.needs.need7"/>
    <b-input class="menu-item" type="text"  placeholder="8" v-model="mPartnership.needs.need8"/>
    <b-row>
      <h1 class="title">Інформаційна допомога</h1>
      <b-input class="menu-item" type="text" placeholder="title" v-model="mPartnership.title4" />
      <b-input class="menu-item" type="text"  placeholder="8" v-model="mPartnership.infoHelp.item1"/>
      <b-input class="menu-item" type="text"  placeholder="8" v-model="mPartnership.infoHelp.item2"/>
      <b-input class="menu-item" type="text"  placeholder="8" v-model="mPartnership.infoHelp.item3"/>
    </b-row>
    <b-row>
      <hr />
      <b-input class="menu-item" type="text" placeholder="title" v-model="mPartnership.title5" />
    </b-row>
    <b-row>
      <b-button @click="saveSupport" style="margin-top: 50px;" type="submit" variant="success">Зберегти зміни</b-button>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mFirestore} from "../../main";

export default {
  name: "Partnership",
  data() {
    return {
      tempItem: '',
      mPartnership: {
        title: '',
        title3: '',
        title4: '',
        subtitle: '',
        paragraph1: '',
        list: [],
        paragraph2: '',
        title2: '',
        steps: {
          step1: {
            title: '',
            subtitle: '',
            index: 1
          },
          step2: {
            title: '',
            subtitle: '',
            index: 2,
          },
          step3: {
            title: '',
            subtitle: '',
            index: 3,
          },
          step4: {
            title: '',
            subtitle: '',
            index: 4
          }
        },
        support: {
          title: '',
          formTitle: '',
          formSubtitle: '',
          formLabels: {
            label1: '',
            label2: ''
          },
          submitText: '',
          text: ''
        },
        needs: {
          need1: '',
          need2: '',
          need3: '',
          need4: '',
          need5: '',
          need6: '',
          need7: '',
          need8: '',
        },
        infoHelp: {
          item1: '',
          item2: '',
          item3: ''
        }
      }
    }
  },
  watch: {
    partnership(value) {
      if(value) {
        this.mPartnership = {
          ...value,
          list: [...value.list],
          steps: {
            step1: {...value.steps.step1},
            step2: {...value.steps.step2},
            step3: {...value.steps.step3},
            step4: {...value.steps.step4}
          },
          support: {...value.support, formLabels: {label1: value.support.formLabels[0],
              label2: value.support.formLabels[1]}
          },
          infoHelp: {
            item1: value.infoHelp[0],
            item2: value.infoHelp[1],
            item3: value.infoHelp[2],
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getPartnership: 'general/getPartnership'
    }),
    deleteItem(idx) {
      this.mPartnership.list.splice(idx,1)
    },
    addItem() {
      if(!this.tempItem || !this.tempItem.length) {
        return
      }
      this.mPartnership.list.push(this.tempItem)
      this.tempItem = ''
    },
    mapData() {
      const resultObj = {
        ...this.mPartnership,
        list: [...this.mPartnership.list],
        steps: {
          step1: {...this.mPartnership.steps.step1},
          step2: {...this.mPartnership.steps.step2},
          step3: {...this.mPartnership.steps.step3},
          step4: {...this.mPartnership.steps.step4}
        },
        needs: {
          need1: this.mPartnership.needs.need1,
          need2: this.mPartnership.needs.need2,
          need3: this.mPartnership.needs.need3,
          need4: this.mPartnership.needs.need4,
          need5: this.mPartnership.needs.need5,
          need6: this.mPartnership.needs.need6,
          need7: this.mPartnership.needs.need7,
          need8: this.mPartnership.needs.need8
        },
      }
      const mFormLabels = []
      Object.values(this.mPartnership.support.formLabels).forEach( val => {
        mFormLabels.push(val)
      })
      const infoHelp = []
      Object.values(this.mPartnership.infoHelp).forEach(val => {
        infoHelp.push(val)
      })
      resultObj.support = {...this.mPartnership.support, formLabels: mFormLabels}
      resultObj.infoHelp = [...infoHelp]
      return resultObj
    },
    saveSupport() {
      mFirestore.collection('partnership').get().then(querySnapshot => {
        querySnapshot.forEach(doc => doc.ref.set(this.mapData()).then(() => {
          this.$toast.success('Зміни збережено!')
        }).catch(err => this.$toast.error(err)))
      })
    }
  },
  computed: {
    ...mapGetters({
      partnership: 'general/partnership'
    })
  },
  mounted() {
    this.getPartnership()
  }
}
</script>

<style scoped>

</style>