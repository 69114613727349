<template>
<main>
  <div id="support">
  <div class="support-title row">
   <Title  :text="support.title"  tag="h1"/>
  </div>
  <h2 class="support-subtitle">{{support.subtitle}}</h2>
  <div class="support container">
    <h3 class="support__title">{{support.paragraph1}}
    </h3>

    <ul class="support__list">
      <li v-for="(el, idx) in support.list" :key="idx">{{el}}</li>
    </ul>

    <h4 class="support__subtitle">{{support.paragraph2}}
    </h4>
    <div class="support__join">
      <Title tag="h5" :text="support.title2" />
    </div>
    <div class="row">
        <StepList :steps="support.steps" />
    </div>
  </div>
  <Help :content="support.support"/>
  </div>
</main>
</template>

<script>
import Help from "../components/Help";
import Title from "../components/Title";
import StepList from "../components/StepList";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "SupportPage",
  components: {StepList, Title, Help},
  computed: {
    ...mapGetters({
      support: 'general/support'
    })
  },
  methods: {
    ...mapActions({
      getSupport: 'general/getSupport'
    })
  },
  mounted() {
    this.getSupport()
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/style/colors';
#support {
  @media(max-width: 1080px) {
    margin-top: 65px;
  }
}
.support-title {
  @media(max-width: 650px) {
    transform: scale(0.6);
  }
}
.support-subtitle {
  font-family: "Bad Script", serif;
  color: $text-grey;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
}
.support {
  &__title {
    font-family: "Century Gothic", serif;
    font-size: 18px;
    line-height: 25px;
    color: $text-grey;
    font-weight: normal;
    padding-top: 90px;
  }
  &__list {
    font-family: "Century Gothic", serif;
    padding-top: 35px;
    font-size: 18px;
    line-height: 25px;
    color: $text-grey;
    padding-left: 15px;
  }
  &__list li {
    list-style-image: url(../assets/images/list-marker.png);
    margin-top: 10px;
  }
  //&__list li::before {
  //    content: url(../assets/images/list-marker.png);
  //    margin-right: 20px;
  //    max-width: 10px;
  //    max-height: 10px;
  //    width: 100%;
  //    height: 100%;
  //}
  &__subtitle {
    font-family: "Century Gothic", serif;
    font-size: 18px;
    line-height: 25px;
    color: $text-grey;
    padding-top: 35px;
  }
  &__join {
    margin-top: 80px;
    @media(max-width: 650px) {
      margin-top: 20px;
      transform: scale(0.6);
    }
  }
}
</style>