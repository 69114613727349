<template>
<div class="general">
  <b-icon-trash-fill :hidden="!itemIndex" style="cursor: pointer" scale="1.5" variant="dark" @click="deleteItem" />
  <b-table :items="forms"
           :fields="fields"
           :sort-by.sync="sortBy"
           :sort-desc.sync="sortDesc"
           sort-icon-left
           no-sort-reset
           selectable select-mode="single"
           @row-selected="selectItem">
    <template #cell(date)="data">
      {{data.item.date.toDate().getDate()}}.{{data.item.date.toDate().getMonth()}}.{{data.item.date.toDate().getFullYear()}}
    </template>
  </b-table>
  <b-row>
    <b-col cols="6">
      <input type="text" class="menu-item" style="margin: 10px" placeholder="Name" v-model="item.name" name="name" id='name' required />
      <masked-input mask="\+\3\8\(111)-111-11-11" type="text" class="menu-item" placeholder="Phone" v-model="item.phone" name="name" id='phone' required />
      <b-button style="margin: 5px" variant="success" @click="addForm">Додати заявку</b-button>
    </b-col>
  </b-row>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mFirestore} from "../../main";
import MaskedInput from 'vue-masked-input'

export default {
  name: "Forms",
  components: {
    MaskedInput
  },
  data() {
    return {
      sortBy: 'date',
      sortDesc: true,
      itemIndex: null,
      fields: [
        {key: 'name', label: 'Name', sortable: false},
        {key: 'phone', label: 'Phone', sortable: false},
        {key: 'date', label: 'Date', sortable: true}
      ],
      item: {
        name: '',
        phone: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      forms: 'general/forms'
    })
  },
  methods: {
    ...mapActions({
      getForms: 'general/getForms',
      createForm: 'general/createForm'
    }),
    selectItem(arg) {
      if(!arg.length) {
        this.itemIndex = null
        return
      }
      this.itemIndex = arg[0].uid
    },
    deleteItem() {
      if(!this.itemIndex) {
        return
      }
      mFirestore.collection('forms').where('uid','==', this.itemIndex).get()
          .then(querySnapshot => {
              querySnapshot.forEach(doc => doc.ref.delete())
            this.$toast.success('Заявку видалено!!!')
      }).catch(err => this.$toast.error(err))
    },
    addForm(){
      if(!this.item.name || !this.item.phone) {
        return
      }
      this.createForm({name: this.item.name, phone: this.item.phone, uid: this.generateUid(), date: new Date()})
          .then(() => {
        this.$toast.success('Заявку створено!!!')
      }).catch(err => this.$toast.error(err))
    },
    generateUid() {
      return this.$uuid.v4()
    },
  },
  mounted() {
    this.getForms()
  }
}
</script>

<style scoped>

</style>