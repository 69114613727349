<template>
<main>
  <div id="photos">
  <div class="photos container">
    <div class="row">
      <Title tag="h1" text="Фотоальбом" />
    </div>
    <div class="photos__inner" v-if="photos">
      <div v-for="(item, idx) in mPhotos" class="photos__item" :key="idx">
        <p class="photos__date">{{item.date.toDate().getDate()}}.{{item.date.toDate().getMonth()}}.
          {{item.date.toDate().getFullYear()}}
        </p>
        <img class="photos__image" :src="item.image" alt=""/>
      </div>
    </div>
  </div>
  </div>
</main>
</template>

<script>
import Title from "../components/Title";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PhotosPage",
  components: {Title},
  methods: {
    ...mapActions({
      getPhotos: 'general/getPhotos'
    })
  },
  computed: {
    ...mapGetters({
      photos: 'general/photos'
    }),
    mPhotos() {
      // eslint-disable-next-line
      return this.photos.photos.reverse()
    }
  },
  mounted() {
    this.getPhotos()
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/style/colors';
#photos {
  @media(max-width: 1080px) {
    margin-top: 65px;
  }
}
.photos {
  &__inner {
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media(max-width: 780px) {
      justify-content: center;
    }
  }
  &__item {
    position: relative;
    max-width: 360px;
    height: 360px;
    @media(max-width: 370px) {
      max-width: 300px;
      height: 300px;
    }
    margin-bottom: 80px;
  }
  &__date {
    font-size: 14px;
    line-height: 25px;
    color: $text-grey;
    position: absolute;
    top: -36px;
    left: 0;
  }
  &__image {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
</style>