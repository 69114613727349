<template>
  <div class="general">
    <div class="photos">
      <div class="photos__item" v-for="(photo, idx) in mPhotos" :key="photo.date.seconds">
        <img class="photo" :src="photo.image" alt=""/>
        <b-icon-trash-fill style="margin-top: 10px; cursor: pointer" scale="1.5" variant="dark" @click="deleteItem(idx)" />
      </div>
    </div>
    <b-row>
      <b-col cols="6" style="padding-top: 50px;">
        <input type="file" @change="setFile">
        <b-button @click="addPhoto" variant="success">Додати фото</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mFirestore, mStorage} from "../../main";

export default {
  name: "Photos",
  data() {
    return {
      mFile: '',
      mPhotos: []
    }
  },
  watch: {
    photos(value) {
      this.mPhotos = [...value.photos]
    }
  },
  methods: {
    ...mapActions({
      getPhotos: 'general/getPhotos'
    }),
    deleteItem(idx) {
      const photo = this.mPhotos[idx]
      if(photo) {
        mStorage.refFromURL(photo.image).delete().then(res => {
          console.log(res)
        }).catch(err => console.log(err))
      }
      this.mPhotos.splice(idx, 1)
      mFirestore.collection('photos').get().then(querySnapshot => {
        querySnapshot.forEach(doc => doc.ref.set({photos: [...this.mPhotos]}))
        this.$toast.success('Image successfully removed!')
      }).catch(err => this.$toast.error(err))
    },
    setFile(event) {
      if(event.target.files[0]) {
        this.mFile = event.target.files[0]
      }
    },
    addPhoto() {
      if(!this.mFile) {
        return
      }
      mStorage.ref().child(`/photos/${this.mFile.name}_${new Date()}`).put(this.mFile).then( async res => {
        this.mPhotos.push({date: new Date(), image: await res.ref.getDownloadURL()})
        await mFirestore.collection('photos').get().then(querySnapshot => {
          querySnapshot.forEach(doc => doc.ref.set({photos: [...this.mPhotos]}))
          this.$toast.success('Image successfully added!')
        }).catch(err => this.$toast.error(err))
      }).catch(err => this.$toast.error(err))
    }
  },
  computed: {
    ...mapGetters({
      photos: 'general/photos'
    })
  },
  mounted() {
    this.getPhotos()
  }
}
</script>

<style scoped>
.photo {
  width: 200px;
  height: 200px;
  margin-right: 30px;
}
.photos {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
</style>