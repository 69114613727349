<template>
<section id="news">
  <div class="news">
    <div class="container">
      <div class="row">
        <Title tag="h3" :text="title" />
      </div>
      <div class="news__items">
<!--        <div class="col-md-4 col-xl-4 col-lg-4">-->
<!--          <NewsItem :item="item"/>-->
<!--        </div>-->
<!--        <div class="col-md-4 col-xl-4 col-lg-4">-->
<!--          <NewsItem :item="item"/>-->
<!--        </div>-->
<!--        <div class="col-md-4 col-xl-4 col-lg-4">-->
<!--          <NewsItem :item="item"/>-->
<!--        </div>-->
        <NewsItem v-for="item in news" :key="item.title" :item="item" />
      </div>
    </div>
  </div>
</section>
</template>

<script>
import NewsItem from "./NewsItem";
import Title from "./Title";

export default {
  name: "News",
  props: {
    news: {
      type: Array
    },
    title: {
      type: String,
      required: true
    }
  },
  components: {
    Title,
    NewsItem
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/style/colors";

#news {
  min-height: 100vh;
  padding-bottom: 80px;
  @media(max-width: 1080px) {
    min-height: 0;
  }
}
.news__items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media(max-width: 780px) {
    justify-content: center;
  }
}
//.news {
//  &__items {
//    padding-top: 80px;
//  }
//}
</style>