import firebase from "firebase";

export default {
    state: () => ({
        user: null,
        error: null,
        showSidebar: false
    }),

    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setError(state, error) {
            state.error = error
        },
        toggleSidebar(state) {
            state.showSidebar = !state.showSidebar
        }
    },

    actions: {
        signIn({commit}, payload) {
              firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
                  return firebase.auth()
                      .signInWithEmailAndPassword(payload.email, payload.password)
                      .then(userCredentials => {
                          commit('setUser', userCredentials.user)
                          localStorage.setItem('isLoggedIn', "true")
                      })
                      .catch( err => commit('setError', err))
              })
        },
        signOut() {
            firebase.auth().signOut().then(() => localStorage.setItem('isLoggedIn', 'false'))
        },
    },

    getters: {
        user: s => s.user,
        error: s => s.error,
        showSidebar: s => s.showSidebar
    },

    namespaced: true
}