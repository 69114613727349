<template>
<header id="header">
  <div class="container header-desktop" v-if="general.menu && general.menu.length">
      <nav class="header-menu">
            <router-link to="/" exact><img class="header-menu__logo" width="70" height="70"
                              :src="general.logo" alt="No image"></router-link>
            <router-link @click.native="scrollAfterRedirect('about-us')"  to="/about" class="header-menu__item">{{general.menu[0]}}</router-link>
            <router-link @click.native="scrollAfterRedirect('our-news')" to="/news" class="header-menu__item">{{general.menu[1]}}</router-link>
            <router-link @click.native="scrollAfterRedirect('our-projects')" to="/projects" class="header-menu__item">{{general.menu[2]}}</router-link>
            <router-link @click.native="scrollAfterRedirect('support')" to="/support" class="header-menu__item">{{general.menu[3]}}</router-link>
            <router-link @click.native="scrollAfterRedirect('partnership')" to="/partnership" class="header-menu__item">{{general.menu[4]}}</router-link>
            <router-link @click.native="scrollAfterRedirect('videos')" to="/videos" class="header-menu__item">{{general.menu[5]}}</router-link>
            <router-link @click.native="scrollAfterRedirect('photos')" to="/photos" class="header-menu__item">{{general.menu[6]}}</router-link>
            <router-link @click.native="scrollAfterRedirect('contacts')" tag="a" to="/#contacts" class="header-menu__item" exact>{{general.menu[7]}}</router-link>
            <router-link @click.native="scrollAfterRedirect('donate')" to="/#donate" class="header-menu__item" exact>{{general.menu[8]}}</router-link>
      </nav>
      <div class="header-phones" v-if="general.phones && general.phones.length">
        <a class="header-phones__item" :href="'tel:' + general.phones[0].link">{{general.phones[0].text}}</a>
        <a class="header-phones__item" :href="'tel:' + general.phones[1].link">{{general.phones[1].text}}</a>
      </div>
  </div>
  <div class="header-mobile">
    <div class="container header-mobile__inner">
    <router-link to="/" exact><img class="header-menu__logo" width="70" height="70"
                                   src="../assets/images/logo.png" alt="No image"></router-link>
      <div id="nav-icon3" @click.prevent="toggleMenu" :class="isMobileMenuOpen ? 'open' : ''">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
  <div v-if="isMobileMenuOpen" class="mobile-menu">
    <router-link @click.native="scrollMobile('about-us')"  to="/about" class="header-menu__item">{{general.menu[0]}}</router-link>
    <router-link @click.native="scrollMobile('our-news')" to="/news" class="header-menu__item">{{general.menu[1]}}</router-link>
    <router-link @click.native="scrollMobile('our-projects')" to="/projects" class="header-menu__item">{{general.menu[2]}}</router-link>
    <router-link @click.native="scrollMobile('support')" to="/support" class="header-menu__item">{{general.menu[3]}}</router-link>
    <router-link @click.native="scrollMobile('partnership')" to="/partnership" class="header-menu__item">{{general.menu[4]}}</router-link>
    <router-link @click.native="scrollMobile('videos')" to="/videos" class="header-menu__item">{{general.menu[5]}}</router-link>
    <router-link @click.native="scrollMobile('photos')" to="/photos" class="header-menu__item">{{general.menu[6]}}</router-link>
    <router-link @click.native="scrollMobile('contacts')" tag="a" to="/#contacts" class="header-menu__item" exact>{{general.menu[7]}}</router-link>
    <router-link @click.native="scrollMobile('donate')" to="/#donate" class="header-menu__item" exact>{{general.menu[8]}}</router-link>
  </div>
</header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "Header",
  data() {
    return {
      isMobileMenuOpen: false
    }
  },
  computed: {
    ...mapGetters({
      general: 'general/general'
    })
  },
  methods: {
    toggleMenu() {
      // console.log('Hello')
      this.isMobileMenuOpen = !this.isMobileMenuOpen
    },
    scrollAfterRedirect(elId) {
        setTimeout(() => {
          this.$smoothScroll({
            scrollTo: document.getElementById(elId),
            updateHistory: false,
            // hash: '#contacts',
            duration: 200
          })
        }, 300)
    },
    scrollMobile(elId) {
      this.toggleMenu()
      this.scrollAfterRedirect(elId)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/style/colors";
 #header {
   max-height: 115px;
   height: 115px;
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   z-index: 9999;
   @media(max-width: 650px) {
     height: auto;
     position: relative;
   }
 }
 #header:hover {
   background-color: rgba($black-color, 0.5);
   transition: 0.5s;
 }
 .header-mobile {
   display: none;
   background-color: rgba($darkgrey-color, 1);
 }
 .header-menu {
   display: flex;
   justify-content: center;
   padding-top: 30px;
   position: relative;
  &__logo {
    position: absolute;
    margin-right: 15px;
    left: 0;
    width: 70px;
    height: 70px;
  }
   &__item {
     font-family: "Century Gothic", serif;
     margin-left: 15px;
     margin-right: 15px;
     font-size: 14px;
     line-height: 16px;
     text-decoration: none;
     color: $white-color;
     text-transform: uppercase;
     height: 20px;
     border-bottom: 2px solid transparent;
   }
   &__item:hover {
     border-bottom: 2px solid $white-color;
     transition: 0.1s;
   }
   &__item:nth-last-child(1) {
     font-weight: bold;
   }
   &__item:nth-last-child(1):hover {
     border-bottom: 0;
     color: $text-blue;
     font-weight: bold;
     transition: 0.2s;
   }
 }
 .header-phones {
   display: flex;
   justify-content: center;
   margin-top: 18px;
   &__item {
     font-family: "Century Gothic", serif;
     font-size: 18px;
     margin-left: 100px;
     margin-right: 100px;
     letter-spacing: 1.2px;
     line-height: 20px;
     text-decoration: none;
     font-weight: bold;
     color: $white-color;
     text-transform: uppercase;
   }
 }
.router-link-active {
  border-bottom: 2px solid $white-color;
}

.router-link-active:nth-last-child(1) {
  color: $text-blue;
  border: none;
}

label{
  display:flex;
  flex-direction:column;
  width:70px;
  cursor:pointer;
}

label span{
  background: #fff;
  border-radius:10px;
  height:7px;
  margin: 7px 0;
  transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);

}


span:nth-of-type(1){
  width:50%;

}

span:nth-of-type(2){
  width:100%;
}


span:nth-of-type(3){
  width:75%;

}


input[type="checkbox"]{
  display:none;
}


input[type="checkbox"]:checked ~ span:nth-of-type(1){
  transform-origin:bottom;
  transform:rotatez(45deg) translate(8px,0px)
}


input[type="checkbox"]:checked ~ span:nth-of-type(2){

  transform-origin:top;
  transform:rotatez(-45deg)
}


input[type="checkbox"]:checked ~ span:nth-of-type(3){

  transform-origin:bottom;
  width:50%;
  transform: translate(30px,-11px) rotatez(45deg);

}


@media(max-width: 980px){
.header-desktop {
  display: none;
}
  .header-mobile {
    display: block;
    height: 65px;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    &__inner {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  .mobile-menu {
    position: fixed;
    width: 100vw;
    height: calc(100vh - 65px);
    top: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 9999;
    background-color: $grey-bg;
  }
  .header-menu__item {
    margin-top: 10px;

    text-align: center;
    max-width: 100%;
  }
  .header-menu__item:hover {
      border: none;
  }
  .header-menu {

    &__logo {
      transform: scale(0.5);
    }
  }
  #header:hover {
    background-color: transparent;
  }
  label {
    transform: scale(0.5);
  }
}
#nav-icon3{
  width: 60px;
  height: 45px;
  position: relative;
  margin-top: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg) scale(0.6);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span{
  display: block;
  position: absolute;
  height: 9px;
  width: 100%;
  background: $white-color;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
  top: 18px;
}

#nav-icon3 span:nth-child(4) {
  top: 36px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

</style>