<template>
  <div id="liqpay_checkout"></div>
</template>

<script>
export default {
  name: "Liqpay",
  props: {},
  mounted() {
    window.LiqPayCheckout.init({
      data:
        "eyJ2ZXJzaW9uIjozLCJhY3Rpb24iOiJwYXlkb25hdGUiLCJhbW91bnQiOiIxMDAiLCJjdXJyZW5jeSI6IlVBSCIsImRlc2NyaXB0aW9uIjoi0JHQu9Cw0LPQvtC00ZbQudC90LjQuSDQstC90LXRgdC+0LoiLCJwdWJsaWNfa2V5IjoiaTY2Mzg5MDU4ODE4IiwibGFuZ3VhZ2UiOiJydSJ9",
      signature: "16XkCS37dJlBkJaRUGdteaSlMOk=",
      embedTo: "#liqpay_checkout",
      mode: "embed",
    });
  },
};
</script>

<style></style>
