<template>
<div class="general">
<b-form @submit.prevent="updateGeneral" class="general__form">
  <b-row>
  <b-col cols="6" class="general__menu">
    <h1 class="title">Меню</h1>
    <b-input class="menu-item" type="text" placeholder="menu1" v-model="mGeneral.menu.item1"/>
    <b-input class="menu-item" type="text" placeholder="menu2" v-model="mGeneral.menu.item2"/>
    <b-input class="menu-item" type="text" placeholder="menu3" v-model="mGeneral.menu.item3"/>
    <b-input class="menu-item" type="text" placeholder="menu4" v-model="mGeneral.menu.item4"/>
    <b-input class="menu-item" type="text" placeholder="menu5" v-model="mGeneral.menu.item5"/>
    <b-input class="menu-item" type="text" placeholder="menu6" v-model="mGeneral.menu.item6"/>
    <b-input class="menu-item" type="text" placeholder="menu7" v-model="mGeneral.menu.item7"/>
    <b-input class="menu-item" type="text" placeholder="menu8" v-model="mGeneral.menu.item8"/>
    <b-input class="menu-item" type="text" placeholder="menu9" v-model="mGeneral.menu.item9"/>
  </b-col>
  <b-col cols="6" class="general__slider">
    <h1 class="title" >Слайдер</h1>
    <div style="overflow-y: auto; max-height: 39.15vh">
      <div class="general__file" v-for="(slide, idx) in mGeneral.slides" :key="idx">
        <img class="general__slide" :src="slide" alt="">
        <span  @click="elIndex = idx">
        <input type="file" @change="changeFile" />
        </span>
        <b-icon-trash-fill style="margin-top: 10px; cursor: pointer"
                           scale="1.5" variant="dark" @click="deleteSlide(idx)" />
      </div>
    </div>
    <div>
      <h3 class="title">Додати зображення:</h3>
      <input type="file" @change="addFile" />
    </div>
  </b-col>
  </b-row>
  <b-row>
    <h1 class="title">Телефони</h1>
    <b-col cols="6">
      <p style="color: #110000">1</p>
      <b-input class="menu-item" type="text" placeholder="phone 1 text" v-model="mGeneral.phones.phone1.text"/>
      <b-input class="menu-item" type="text" placeholder="phone 1 link" v-model="mGeneral.phones.phone1.link"/>
    </b-col>
    <b-col cols="6">
      <p style="color: #110000">2</p>
      <b-input class="menu-item" type="text" placeholder="phone 2 text" v-model="mGeneral.phones.phone2.text"/>
      <b-input class="menu-item" type="text" placeholder="phone 2 link" v-model="mGeneral.phones.phone2.link"/>
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="6">
      <h1 class="title">Про нас</h1>
      <b-input class="menu-item" type="text" placeholder="about title" v-model="mGeneral.about.title" />
      <b-textarea class="menu-item" placeholder="Paragraph 1" v-model="mGeneral.about.paragraph1" />
      <b-textarea class="menu-item" placeholder="Paragraph 2" v-model="mGeneral.about.paragraph2" />
    </b-col>
    <b-col cols="6">
      <div class="general__file-about" >
        <img class="general__preview" :src="mGeneral.about.image" alt="">
        <span>
        <input type="file" @change="changeAboutFile" />
        </span>
      </div>
    </b-col>
  </b-row>
  <b-row>
    <h1 class="title">Долучитися</h1>
    <b-col cols="6">
      <b-input class="menu-item" type="text" placeholder="support title" v-model="mGeneral.support.title" />
      <div class="text-editor">
        <wysiwyg v-model="mGeneral.support.text" />
      </div>
    </b-col>
    <b-col cols="6">
      <b-input class="menu-item" type="text" placeholder="support form title" v-model="mGeneral.support.formTitle" />
      <b-input class="menu-item" type="text"
               placeholder="support form subtitle" v-model="mGeneral.support.formSubtitle" />
      <b-input class="menu-item" type="text" placeholder="input 1" v-model="mGeneral.support.formLabels.label1" />
      <b-input class="menu-item" type="text" placeholder="input 2" v-model="mGeneral.support.formLabels.label2" />
      <b-input class="menu-item" type="text" placeholder="button text" v-model="mGeneral.support.submitText" />
    </b-col>
  </b-row>
  <b-row>
    <h1 class="title">Новини</h1>
    <b-input class="menu-item" type="text" placeholder="news title" v-model="mGeneral.newsTitle" />
  </b-row>
  <b-row>
    <h1 class="title">Фінансова допомога</h1>
    <b-input class="menu-item" type="text" placeholder="donate title" v-model="mGeneral.donate.title" />
    <b-textarea class="menu-item" type="text" placeholder="donate text" v-model="mGeneral.donate.text" />
  </b-row>
  <b-row>
    <h1 class="title">Контакти</h1>
    <b-row>
      <b-col cols="6">
        <b-input class="menu-item" type="text" placeholder="contacts title" v-model="mGeneral.contacts.title" />
      </b-col>
    </b-row>
    <b-col cols="6">
      <p style="color: #110000">Адреса:</p>
      <b-input class="menu-item" type="text" placeholder="location" v-model="mGeneral.contacts.location" />
      <p style="color: #110000">Фейсбук:</p>
      <b-input class="menu-item" type="text" placeholder="facebook" v-model="mGeneral.contacts.facebook" />
      <b-input class="menu-item" type="text" placeholder="facebook url" v-model="mGeneral.contacts.facebookUrl" />
      <p style="color: #110000">Інстаграм:</p>
      <b-input class="menu-item" type="text" placeholder="instagram" v-model="mGeneral.contacts.instagram" />
      <b-input class="menu-item" type="text" placeholder="instagram url" v-model="mGeneral.contacts.instagramUrl" />
      <p style="color: #110000">Електронна адреса:</p>
      <b-input class="menu-item" type="text" placeholder="email" v-model="mGeneral.contacts.email" />
      <p style="color: #110000">Телефон:</p>
      <b-input class="menu-item" type="text" placeholder="phone" v-model="mGeneral.contacts.phone" />
    </b-col>
    <b-row>
      <h1 class="title">Адреса</h1>
      <b-textarea class="menu-item" placeholder="google maps url" v-model="mGeneral.contacts.addrUrl" />
    </b-row>
  </b-row>
  <b-row>
    <b-button style="margin-top: 50px;" type="submit" variant="success">Зберегти зміни</b-button>
  </b-row>
</b-form>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mFirestore, mStorage} from "../../main";

export default {
  name: "General",
  data() {
    return {
      elIndex: 0,
      mGeneral: {
        logo: null,
        menu: {
          item1: null,
          item2: null,
          item3: null,
          item4: null,
          item5: null,
          item6: null,
          item7: null,
          item8: null,
          item9: null,
        },
        phones: {
          phone1: {
            text: '',
            link: ''
          },
          phone2: {
            text: '',
            link: ''
          }
        },
        slides: [],
        about: {
          title: '',
          paragraph1: '',
          image: '',
          paragraph2: ''
        },
        support: {
          title: '',
          formTitle: '',
          formSubtitle: '',
          formLabels: {
            label1: '',
            label2: ''
          },
          submitText: '',
          text: ''
        },
        newsTitle: '',
        donate: {
          title: '',
          text: ''
        },
        contacts: {
          title: '',
          location: '',
          facebook: '',
          facebookUrl: '',
          instagram: '',
          instagramUrl: '',
          email: '',
          phone: '',
          addrUrl: '',
        }
      },
    }
  },
  computed: {
    ...mapGetters({
      general: 'general/general'
    })
  },
  watch: {
    general(value) {
      this.mGeneral.logo = value.logo

      // Menu
      Object.keys(this.mGeneral.menu).forEach(
          (key, idx) => {
            if(this.general) {
              this.mGeneral.menu[key] = value.menu[idx]
            }
          }
      )

      // Phones
      this.mGeneral.phones.phone1 = value.phones[0]
      this.mGeneral.phones.phone2 = value.phones[1]

      // About
      this.mGeneral.about.paragraph1 = value.about.paragraph1
      this.mGeneral.about.title = value.about.title
      this.mGeneral.about.paragraph2 = value.about.paragraph2
      this.mGeneral.about.image = value.about.image

      // Support
      this.mGeneral.support.title = value.support.title
      this.mGeneral.support.formTitle = value.support.formTitle
      this.mGeneral.support.formSubtitle = value.support.formSubtitle
      this.mGeneral.support.submitText = value.support.submitText
      Object.keys(this.mGeneral.support.formLabels).forEach(
          (key, idx) => {
            this.mGeneral.support.formLabels[key] = value.support.formLabels[idx]
          }
      )
      this.mGeneral.support.text = value.support.text

      // News
      this.mGeneral.newsTitle = value.newsTitle

      // Donate
      this.mGeneral.donate.title = value.donate.title
      this.mGeneral.donate.text = value.donate.text

      // Contacts
      this.mGeneral.contacts.location = value.contacts.location
      this.mGeneral.contacts.facebook = value.contacts.facebook
      this.mGeneral.contacts.facebookUrl = value.contacts.facebookUrl
      this.mGeneral.contacts.instagram = value.contacts.instagram
      this.mGeneral.contacts.instagramUrl = value.contacts.instagramUrl
      this.mGeneral.contacts.email = value.contacts.email
      this.mGeneral.contacts.phone = value.contacts.phone
      this.mGeneral.contacts.title = value.contacts.title
      this.mGeneral.contacts.addrUrl = value.contacts.addrUrl

     // Slider
     this.mGeneral.slides.push(...value.slides)
    }
  },
  methods: {
    ...mapActions({
      getGeneral: 'general/getGeneral',
    }),
   changeFile(event) {
     const file = event.target.files[0]
     const oldLink = this.mGeneral.slides[this.elIndex]
     const oldSlideRef = mStorage.refFromURL(oldLink)
     oldSlideRef.put(file)
      .then(async res => {
        this.mGeneral.slides.splice(this.elIndex,1,await res.ref.getDownloadURL())
        this.$toast.success('Image successfully updated!')
   }).catch(err => this.$toast.error(err))
   },
    deleteSlide(index) {
      const oldLink = this.mGeneral.slides[index]
      const oldSlideRef = mStorage.refFromURL(oldLink)
      oldSlideRef.delete().then( () => {
        this.mGeneral.slides.splice(index, 1);
            mFirestore.collection('general').get().then( querySnapshot => {
              querySnapshot.forEach( doc => {
                const prevStateOfObject = doc.data()
                doc.ref.set(
                    {
                      ...prevStateOfObject,
                      slides: [...this.mGeneral.slides]
                    }
                )
              })
              this.$toast.success('Image successfully deleted!')
            }).catch(err => this.$toast.error(err))
      }).catch(err => this.$toast.error(err))
    },
    addFile(event) {
      const file = event.target.files[0]
      if(!file)
      {
        this.$toast.error('File is not selected!')
        return
      }
      mStorage.ref().child(`/slider/${file.name}_${new Date().getTime()}`).put(file).then( async res => {
        this.mGeneral.slides.push(await res.ref.getDownloadURL())
        mFirestore.collection('general').get().then( querySnapshot => {
          querySnapshot.forEach( doc => {
            const prevStateOfObject = doc.data()
                doc.ref.set(
                {
                  ...prevStateOfObject,
                  slides: [...this.mGeneral.slides]
                }
            )
          })
          this.$toast.success('Image successfully uploaded!')
        }).catch(err => this.$toast.error(err))
      }).catch(err => this.$toast.error(err))
    },
    changeAboutFile(event) {
      const file = event.target.files[0]
      const oldRef = mStorage.refFromURL(this.mGeneral.about.image)
      oldRef.put(file).then( async res => {
        this.mGeneral.about.image = await res.ref.getDownloadURL()
        this.$toast.success('Image successfully updated!')
      }).catch( err => this.$toast.error(err))
    },
    mapData() {
      const resultObj = {
        slides: [],
        menu: [],
        phones: [],
        about:{
          title:'',
          paragraph1: '',
          image: null,
          paragraph2: ''
        },
        logo: null,
        contacts: {
          title: '',
          location: '',
          facebook: '',
          facebookUrl: '',
          instagram: '',
          instagramUrl: '',
          email: '',
          phone: '',
        },
        newsTitle: '',
        donate: {
          title: '',
          text: '',
        },
        support: {
          title: '',
          text: '',
          formTitle: '',
          formSubtitle: '',
          formLabels: [],
          submitText: ''
        }
      }
      resultObj.logo = this.mGeneral.logo
      Object.values(this.mGeneral.menu).forEach( val => {
        resultObj.menu.push(val)
      })
      resultObj.slides = [...this.mGeneral.slides]
      Object.values(this.mGeneral.phones).forEach( val => {
        resultObj.phones.push(val)
      })
      resultObj.about = {...this.mGeneral.about}
      resultObj.contacts = {...this.mGeneral.contacts}
      resultObj.newsTitle = this.mGeneral.newsTitle
      resultObj.donate = {...this.mGeneral.donate}
      const mFormLabels = []
      Object.values(this.mGeneral.support.formLabels).forEach( val => {
        if(val)
        {
          mFormLabels.push(val)
        } else {
          mFormLabels.push("")
        }
      })
      resultObj.support = {...this.mGeneral.support, formLabels: mFormLabels}
      return resultObj
    },
    updateGeneral() {
      mFirestore.collection('general').get().then(querySnapshot => {
        querySnapshot.forEach(doc => doc.ref.set(this.mapData()).then(() => {
          this.$toast.success('Зміни збережено!')
        }).catch(err => {
          this.$toast.error(err)
        }))
      }).catch( () => this.$toast.error('Please fill all fields!'))
    }
   },
  created() {
    this.getGeneral()
  },
  beforeDestroy() {
  }
}
</script>

<style lang="scss">
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
.general {
  padding: 40px;
  &__menu {
    max-width: 180px;
    max-height: 80vh;
  }
  &__slide {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;

  }
  &__file {
    display: flex;
    justify-content: flex-start;
  }
  &__file-about {
    padding-top: 72px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &__preview {
    width: 250px;
    height: 180px;
  }
  &__preview-qr {
    width: 160px;
    height: 160px;
  }
}
.menu-item {
  margin-bottom: 5px;
}
.title {
  color: #110000;
  line-height: 72px;
}
.text-editor {
  color: #110000;
}
input[type=file] {
  color: #110000;
}
</style>