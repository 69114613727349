<template>
  <section id="projects">
    <div class="projects">
      <div class="container">
        <div v-if="title" class="row projects__inner">
          <h1 class="projects__title">Наші соціальні програми розраховані на те, щоб зняти соціальну
            напругу міського суспільства, покращити якість та рівень життя
            визначенних соціальних груп.</h1>
        </div>
        <div class="projects__items">
          <!--        <div class="col-md-4 col-xl-4 col-lg-4">-->
          <!--          <NewsItem :item="item"/>-->
          <!--        </div>-->
          <!--        <div class="col-md-4 col-xl-4 col-lg-4">-->
          <!--          <NewsItem :item="item"/>-->
          <!--        </div>-->
          <!--        <div class="col-md-4 col-xl-4 col-lg-4">-->
          <!--          <NewsItem :item="item"/>-->
          <!--        </div>-->
          <ProjectsItem v-for="item in projects" :key="item.title" :item="item" />
        </div>
        <router-link v-if="btn" tag="button" class="all-projects-btn" to="/projects">Всі проєкти</router-link>
      </div>
    </div>
  </section>
</template>

<script>
import ProjectsItem from "./ProjectsItem";

export default {
  name: "Projects",
  props: {
    projects: {
      type: Array,
      required: true
    },
    title: {
      type: Boolean,
      required: true
    },
    btn: {
      type: Boolean,
      required: true
    }
  },
  components: {
    ProjectsItem
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/style/colors";

#projects {
  min-height: 100vh;
  padding-bottom: 80px;
}
.projects__items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media(max-width: 780px) {
    justify-content: center;
  }
}
.projects__title {
  font-family: "Bad Script", serif;
  font-size: 30px;
  line-height: 40px;
  color: $text-blue;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}
.all-projects-btn {
  padding: 10px 75px;
  margin-top: 80px;
  font-size: 18px;
  line-height: 25px;
  background-color: transparent;
  border: 2px solid $text-blue;
  color: $text-blue;
  cursor: pointer;
  margin-left: calc(50% - 128px);
}
.all-projects-btn:hover {
  transform: scale(1.1);
  transition: 0.5s;
}
</style>