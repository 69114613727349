<template>
<b-table striped hover :items="items" sticky-header="" :fields="fields" @row-clicked="onRowClick">
<template #cell(content)="">
</template>
  <template #cell(image)="">
  </template>
  <template #cell(previewImage)="">
  </template>
  <template #cell(date)="data" >
    {{data.item.date.toDate().getDate()}}.{{data.item.date.toDate().getMonth()}}.{{data.item.date.toDate().getFullYear()}}
  </template>
</b-table>
</template>

<script>
export default {
  name: "Table",
  props: ['items'],
  data() {
    return {
      fields: [
        {key: 'title', label: 'Title'},
        {key: 'subtitle', label: 'Subtitle'},
        {key: 'date', label: 'Date'},
      ]
    }
  },
  methods: {
    onRowClick(arg) {
      this.$emit('onRowClick', arg.uid)
    }
  }
}
</script>

<style scoped>

</style>