import Home from "./pages/Home";
import AboutPage from "./pages/AboutPage";
import NewsPage from "./pages/NewsPage";
import ProjectsPage from "./pages/ProjectsPage";
import SupportPage from "./pages/SupportPage";
import NewsDetailPage from "./pages/NewsDetailPage";
import ProjectsDetailPage from "./pages/ProjectsDetailPage";
import PartnershipPage from "./pages/PartnershipPage";
import PhotosPage from "./pages/PhotosPage";
import VideosPage from "./pages/VideosPage";
import Index from "./pages/admin/Index";
import Login from "./pages/admin/Login";
import General from "./pages/admin/General";
import About from "./pages/admin/About";
import Support from "./pages/admin/Support";
import Partnership from "./pages/admin/Partnership";
import Photos from "./pages/admin/Photos";
import Videos from "./pages/admin/Videos";
import News from "./pages/admin/News";
import Projects from "./pages/admin/Projects";
import NewsDetail from "./pages/admin/NewsDetail";
import ProjectsDetail from "./pages/admin/ProjectsDetail";
import Forms from "./pages/admin/Forms";
import Profile from "./pages/admin/Profile";

const isAuthenticated = (to, from, next) => {
        if(localStorage.getItem('isLoggedIn') === "true") {
            next()
        } else {
            next('/login')
        }
}

const routes = [
    {
        path: '',
        component: Home,
        meta: {layout: 'main'}
    },
    {
        path: '/about',
        component: AboutPage,
        meta: {layout: 'main'}
    },
    {
        path: '/news',
        component: NewsPage,
        meta: {layout: 'main'}
    },
    {
        path: '/projects',
        component: ProjectsPage,
        meta: {layout: 'main'}
    },
    {
      path: '/partnership',
      component: PartnershipPage,
      meta: {layout: 'main'}
    },
    {
      path: '/videos',
      component: VideosPage,
      meta: {layout: 'main'}
    },
    {
      path: '/photos',
      component: PhotosPage,
      meta: {layout: 'main'}
    },
    {
        path: '/support',
        component: SupportPage,
        meta: {layout: 'main'}
    },
    {
        path: '/news/:uid',
        component: NewsDetailPage,
        meta: {layout: 'main'}
    },
    {
        path: '/projects/:uid',
        component: ProjectsDetailPage,
        meta: {layout: 'main'}
    },
    {
      path: '/login',
      component: Login,
      meta: {layout: 'empty'}
    },
    {
        path: '/administrator',
        component: Index,
        meta: {layout: 'empty'},
        beforeEnter: isAuthenticated,
        children: [
            {
                path: '',
                component: General,
                meta: {layout: 'empty'}
            },
            {
                path: 'about',
                component: About,
                meta: {layout: 'empty'}
            },
            {
                path: 'news',
                component: News,
                meta: {layout: 'empty'}
            },
            {
                path: 'news/:uid',
                component: NewsDetail,
                meta: {layout: 'empty'}
            },
            {
                path: 'projects',
                component: Projects,
                meta: {layout: 'empty'}
            },
            {
                path: 'projects/:uid',
                component: ProjectsDetail,
                meta: {layout: 'empty'}
            },
            {
                path: 'support',
                component: Support,
                meta: {layout: 'empty'}
            },
            {
                path: 'partnership',
                component: Partnership,
                meta: {layout: 'empty'}
            },
            {
              path: 'video',
              component: Videos,
              meta: {layout: 'empty'}
            },
            {
                path: 'photo',
                component: Photos,
                meta: {layout: 'empty'}
            },
            {
                path: 'forms',
                component: Forms,
                meta: {layout: 'empty'}
            },
            {
                path: 'profile',
                component: Profile,
                meta: {layout: 'empty'}
            }
        ]
    }
]

export default routes