<template>
  <section id="contacts">
    <div class="contacts">
      <div class="container">
        <div class="row">
          <Title tag="h4" :text="content.title" />
        </div>
        <div class="row contacts__content">
          <div
            class="col-md-12 col-lg-6 col-xl-6 col-sm-12 col-xs-12 contacts__inner"
          >
            <div class="contacts__item">
              <img
                class="contacts__img"
                src="../assets/images/pin.png"
                alt=""
              />
              <p class="contacts__text">{{ content.location }}</p>
            </div>
            <div class="contacts__item">
              <img
                class="contacts__img"
                src="../assets/images/facebook.png"
                alt=""
              />
              <a
                target="_blank"
                :href="content.facebookUrl"
                class="contacts__text contacts__text-link"
              >
                {{ content.facebook }}</a
              >
            </div>
            <div class="contacts__item">
              <Instagram />
              <a
                target="_blank"
                :href="content.instagramUrl"
                class="contacts__text contacts__text-link"
              >
                {{ content.instagram }}</a
              >
            </div>
            <div class="contacts__item">
              <img
                class="contacts__img"
                src="../assets/images/email.png"
                alt=""
              />
              <p class="contacts__text">{{ content.email }}</p>
            </div>
            <div class="contacts__item">
              <img
                class="contacts__img"
                src="../assets/images/phone.png"
                alt=""
              />
              <p class="contacts__text">{{ content.phone }}</p>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 col-xl-6 col-sm-12 col-xs-12 map">
            <iframe
              width="490"
              height="310"
              style="border:0"
              loading="lazy"
              allowfullscreen
              :src="content.addrUrl"
            >
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Title from "./Title";
import Instagram from "./icons/Instagram";
export default {
  name: "Contacts",
  components: { Instagram, Title },
  props: {
    content: {},
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/colors";

#contacts {
  min-height: 100vh;
  background-color: $white-color;
  @media (max-width: 1080px) {
    min-height: 0;
    padding-bottom: 80px;
  }
}

.contacts {
  height: 100%;
  &__content {
    padding-top: 80px;
  }
  &__inner {
    padding-top: 15px;
  }
  &__item {
    display: flex;
    justify-content: start;
    text-align: center;
    width: 100%;
  }
  &__img {
    max-width: 18px;
    max-height: 18px;
    margin-top: 12px;
  }
  &__text {
    font-family: "Century Gothic", serif;
    font-size: 14px;
    line-height: 14px;
    color: $text-grey;
    padding-top: 15px;
    padding-left: 10px;
    @media (max-width: 350px) {
      font-size: 13px;
      max-width: 300px;
    }
  }
  &__text-link:hover {
    color: $text-blue;
  }
}
.qr-image {
  max-width: 115px;
}
@media (max-width: 1080px) {
  .map {
    margin-top: 20px;
  }
}
@media (max-width: 650px) {
  .map {
    overflow: hidden;
    padding-bottom: 310px;
    margin-top: 20px;
    position: relative;
    height: 0;
  }

  .map iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
</style>
