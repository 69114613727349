<template>
  <div class="general">
    <b-row>
      <b-input class="menu-item" type="text" placeholder="Title" v-model="item.title"/>
      <b-input class="menu-item" type="text" placeholder="Subtitle" v-model="item.subtitle"/>
      <p style="color: #110000;">Banner</p>
      <input type="file" @change="changeBanner">
      <img style="width: 500px; height: 200px" :src="item.image" alt="" />
      <p style="color: #110000;">Preview</p>
      <input type="file" @change="changePreview">
      <img style="width: 300px; height: 200px" :src="item.previewImage" alt="" />
      <wysiwyg style="color: #110000;" v-model="item.content" />
    </b-row>
    <b-row>
      <b-button variant="success" @click="updateItem" >Змінити проєкт</b-button>
      <b-button style="margin-top: 50px" variant="danger" @click="deleteItem" >Видалити проєкт</b-button>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {mFirestore, mStorage} from "../../main";

export default {
  name: "ProjectsDetail",
  data() {
    return {
      item: {

      }
    }
  },
  methods: {
    changeBanner(event) {
      const file = event.target.files[0]
      if(!file) {
        return
      }
      mStorage.refFromURL(this.item.image).put(file).then( async res => {
        this.item.image = await res.ref.getDownloadURL()
      }).then(() => this.$toast.success('Image successfully updated!'))
          .catch(err => this.$toast.error(err))
    },
    changePreview(event) {
      const file = event.target.files[0]
      if(!file) {
        return
      }
      mStorage.refFromURL(this.item.previewImage).put(file).then( async res => {
        this.item.previewImage = await res.ref.getDownloadURL()
      }).then(() => this.$toast.success('Image successfully updated!'))
          .catch(err => this.$toast.error(err))
    },
    updateItem() {
      mFirestore.collection('projects').where('uid', "==", this.$route.params.uid).get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => doc.ref.set({...this.item}))
            this.$router.push('/administrator/projects')
            this.$toast.success('Project successfully updated!')
          }).catch(err => this.$toast.error(err))
    },
    deleteItem() {
      mStorage.refFromURL(this.item.image).delete().then(() => {
        mStorage.refFromURL(this.item.previewImage).delete().then(() => {
          mFirestore.collection('projects').where('uid', "==", this.$route.params.uid).get()
              .then(querySnapshot => {
                querySnapshot.forEach(doc => doc.ref.delete())
                this.$router.push('/administrator/projects')
                this.$toast.success('Project successfully deleted!')
              }).catch(err => this.$toast.error(err))
        }).catch(err => this.$toast.error(err))
      }).catch(err => this.$toast.error(err))
    },
    ...mapActions({
      getProjectsItem: 'general/getProjectsItem'
    }),
    ...mapMutations({
      setProjectsItem: 'general/setProjectsItem'
    })
  },
  watch: {
    projectsItem(value) {
      this.item = {...value}
    }
  },
  computed: {
    ...mapGetters({
      projectsItem: 'general/projectsItem'
    })
  },
  mounted() {
    this.getProjectsItem(this.$route.params.uid)
  },
  destroyed() {
    this.setProjectsItem(null)
  }
}
</script>

<style scoped>

</style>