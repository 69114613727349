import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store/index'

// Uuid
import Uuid from 'vue-uuid'
Vue.use(Uuid)

// Vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate)

//Wysiwyg
import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {hideModules: {
    "link": true,
    "code": true,
    "orderedList": true,
    "unorderedList": true,
    "image": true,
    "table": true,
    "removeFormat": true,
    "separator": true
  }});

// Toast
import VueToastificationPlugin, {POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
  position: POSITION.TOP_CENTER
}
Vue.use(VueToastificationPlugin, options)

// Smooth scroll plugin
import VueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(VueSmoothScroll)

// Bootstrap vue
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// Firebase instance
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
firebase.initializeApp({
    apiKey: "AIzaSyAK_al11ZkjGudof4wW4vA00aDWLhOG_dc",
    authDomain: "terytoria-dobra-ef910.firebaseapp.com",
    projectId: "terytoria-dobra-ef910",
    storageBucket: "terytoria-dobra-ef910.appspot.com",
    messagingSenderId: "597224921153",
    appId: "1:597224921153:web:bdf97f13fe0800530f4ec0",
    measurementId: "G-XHB7V41CKN"
})

export const mFirestore = firebase.firestore()
export const mStorage = firebase.storage()

//css
import './assets/style/style.scss'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
