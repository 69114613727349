<template>
  <div class="general">
    <b-row>
      <div class="videos" v-for="(video, idx) in mVideos" :key="idx">
        <div v-html="video">
        </div>
        <b-icon-trash-fill style="margin-top: 10px; cursor: pointer" scale="1.5" variant="dark" @click="deleteItem(idx)" />
      </div>
    </b-row>
    <b-row>
      <b-textarea type="text" v-model="tempLink" />
      <b-button variant="success" @click="addVideo">Add video</b-button>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mFirestore} from "../../main";

export default {
  name: "Videos",
  data() {
    return {
      tempLink: '',
      mVideos: []
    }
  },
  methods: {
    ...mapActions({
      getVideos: 'general/getVideos'
    }),

    deleteItem(idx) {
      this.mVideos.splice(idx, 1)
      mFirestore.collection('videos').get().then(querySnapshot => {
        querySnapshot.forEach(doc => doc.ref.set({videos: [...this.mVideos]}))
        this.$toast.success('Video successfully deleted!')
      }).catch(err => this.$toast.error(err))
    },

    addVideo() {
      if(!this.tempLink) {
        return
      }
      this.mVideos.push(this.tempLink)
      mFirestore.collection('videos').get().then(querySnapshot => {
        querySnapshot.forEach(doc => doc.ref.set({videos: [...this.mVideos]}))
        this.tempLink = ''
        this.$toast.success('Videos successfully created!')
      }).catch(err => this.$toast.error(err))
    }
  },
  watch: {
    videos(value) {
      this.mVideos = [...value.videos]
    }
  },
  computed: {
    ...mapGetters({
      videos: 'general/videos'
    })
  },
  mounted() {
    this.getVideos()
  }
}
</script>

<style scoped>

</style>