<template>
<div class="news-item">
  <p class="news-item__date">
    {{item.date.toDate().getDate()}}.{{item.date.toDate().getMonth()}}.{{item.date.toDate().getFullYear()}}</p>
  <div class="news-item__inner" :style="`background: url('${item.previewImage}');`">
    <div class="news-item__content">
      <p class="news-item__title">
        {{item.title}}
      </p>
      <p class="news-item__subtitle">
        {{item.subtitle}}
      </p>

      <router-link tag="button" :to="`/news/${item.uid}`" class="news-item__button">
        Детальніше
      </router-link>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  name: "NewsItem"
}
</script>

<style lang="scss" scoped>
@import '../assets/style/colors';

  .news-item {
    font-family: "Century Gothic", serif;
    position: relative;
    margin-top: 80px;
    &__inner {
      max-width: 360px;
      height: 360px;
      @media(max-width: 370px) {
          max-width: 300px;
        height: 300px;
      }
      position: relative;
      background-repeat: no-repeat;
    }
    &__date {
      font-size: 14px;
      line-height: 25px;
      color: $text-grey;
      position: absolute;
      top: -36px;
      left: 0;
    }
    &__content {
      text-align: center;
      z-index: 100;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 35px 40px;
      @media(max-width: 370px) {
        padding: 10px 15px;
      }
    }
    &__title {
      font-size: 20px;
      line-height: 25px;
      font-weight: bold;
      color: $white-color;
    }
    &__subtitle {
      font-size: 18px;
      line-height: 25px;
      color: $white-color;
    }
    &__button {
      padding: 10px 75px;
      font-size: 18px;
      line-height: 25px;
      background-color: transparent;
      border: 2px solid $white-color;
      color: $white-color;
      cursor: pointer;
    }

    &__button:hover {
      transform: scale(1.1);
      transition: 0.5s;
    }
  }
</style>