<template>
<div>
  <slot />
</div>
</template>

<script>
import firebase from "firebase";
import {mapMutations} from "vuex";

export default {
  name: "EmptyLayout",
  mounted() {
    firebase.auth().onAuthStateChanged(user => {
      if(user) {
        firebase.auth().updateCurrentUser(user)
      }
      // console.log(user)
    })
  },
  methods: {
    ...mapMutations({
      setUser: 'admin/setUser'
    })
  }
}
</script>

<style scoped>

</style>