<template>
<VueSlickCarousel
    class="banner" v-if="general.slides && general.slides.length" v-bind="settings">
    <img v-for="(image, idx) in general.slides" :key="idx" class="banner__slide" data-dot="<img src='../assets/images/heart-empty.png' alt=''/>"
         :src="image"  alt=""/>
<!--    <img class="banner__slide" data-dot="<img src='../assets/images/heart-empty.png'/>"-->
<!--         src="../assets/images/Banner.png"  alt=""/>-->
<!--    <img class="banner__slide" data-dot="<img src='../assets/images/heart-empty.png'/>"-->
<!--         src="../assets/images/Banner.png"  alt=""/>-->
<!--    <img class="banner__slide" data-dot="<img src='../assets/images/heart-empty.png'/>"-->
<!--         src="../assets/images/Banner.png"  alt=""/>-->

</VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import {mapGetters} from "vuex";

export default {
  name: "Slider",
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        dots: true,
        slidesToShow: 1,
        loop: true,
        arrows: false,
        swipe: false,
        lazyLoad: 'progressive',

      }
    }
  },
  computed: {
    ...mapGetters({
      general: 'general/general'
    })
  },
}
</script>

<style lang="scss">
  .banner {
    @media(max-width: 980px) {
      display: none!important;
    }
    &__slide {
      width: 100vw;
      height: 100vh;
    }
  }
  .slick-dots {
    z-index: 9998;
    list-style: none;
    position: absolute;
    bottom:30px;
    left: 3.5%;
    @media(min-width: 1620px) {
      left: 13%;
    }
    display: flex!important;
    justify-content: start;
  }
  .slick-dots li button {
    background: url(../assets/images/heart-empty.png);
    text-indent: -9999px;
    border:0;
    width: 20px;
    height: 18px;
    margin-left: 7px;
    margin-right: 7px;
    /* more CSS */
  }
  .slick-dots .slick-active button {
    background: url(../assets/images/heart-full.png);
    text-indent: -9999px;
    border:0;
    /* more CSS */
  }

</style>