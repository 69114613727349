<template>
  <main>
    <div id="about-us">
    <About :content-top="mAboutPage.paragraph1"
           :content-main="mAboutPage.paragraph2"
           :title-text="mAboutPage.title"
           :image="mAboutPage.image"/>
    </div>
  </main>
</template>

<script>
import About from "../components/About";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AboutPage",
  components: {
    About
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      mAboutPage: 'general/about'
    })
  },
  methods: {
    ...mapActions({
      getAbout: 'general/getAbout'
    })
  },
  created() {
    this.getAbout()
  }
}
</script>

<style lang="scss" scoped>
#about-us{
  @media(max-width: 1080px) {
    margin-top: 65px;
  }
}
</style>