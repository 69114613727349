<template>
  <footer id="footer">
    <div class="container">
      <nav class="footer-menu">
        <router-link to="/" exact><img class="footer-menu__logo" width="70" height="70"
                                       :src="general.logo" alt="No image"></router-link>
        <router-link @click.native="scrollAfterRedirect('about-us')"  to="/about" class="footer-menu__item">{{general.menu[0]}}</router-link>
        <router-link @click.native="scrollAfterRedirect('our-news')" to="/news" class="footer-menu__item">{{general.menu[1]}}</router-link>
        <router-link @click.native="scrollAfterRedirect('our-projects')" to="/projects" class="footer-menu__item">{{general.menu[2]}}</router-link>
        <router-link @click.native="scrollAfterRedirect('support')" to="/support" class="footer-menu__item">{{general.menu[3]}}</router-link>
        <router-link @click.native="scrollAfterRedirect('partnership')" to="/partnership" class="footer-menu__item">{{general.menu[4]}}</router-link>
        <router-link @click.native="scrollAfterRedirect('videos')" to="/videos" class="footer-menu__item">{{general.menu[5]}}</router-link>
        <router-link @click.native="scrollAfterRedirect('photos')" to="/photos" class="footer-menu__item">{{general.menu[6]}}</router-link>
        <router-link @click.native="scrollAfterRedirect('contacts')" tag="a" to="/#contacts" class="footer-menu__item" exact>{{general.menu[7]}}</router-link>
        <router-link @click.native="scrollAfterRedirect('donate')" to="/#donate" class="footer-menu__item" exact>{{general.menu[8]}}</router-link>
      </nav>
      <div class="footer-phones">
        <a class="footer-phones__item" :href="'tel:' + general.phones[0].link">{{general.phones[0].text}}</a>
        <a class="footer-phones__item" :href="'tel:' + general.phones[1].link">{{general.phones[1].text}}</a>
      </div>
    </div>
  </footer>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Header",
  data() {
    return {
    }
  },
  computed: {
      ...mapGetters({
        general: 'general/general'
      })
  },
  methods: {
    scrollAfterRedirect(elId) {
      this.$smoothScroll({
        scrollTo: document.getElementById(elId),
        updateHistory: false,
        // hash: '#contacts',
        duration: 200
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/style/colors";
#footer {
  min-height: 115px;
  //height: 115px;
  z-index: 9999;
  background-color: $darkgrey-color;
}
.footer-menu {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  position: relative;
  &__logo {
    position: absolute;
    margin-right: 15px;
    left: 0;
    width: 70px;
    height: 70px;
  }
  &__item {
    font-family: "Century Gothic", serif;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    color: $white-color;
    text-transform: uppercase;
    height: 20px;
    border-bottom: 2px solid transparent;
  }
  &__item:hover {
    border-bottom: 2px solid $white-color;
    transition: 0.1s;
  }
  &__item:nth-last-child(1) {
    font-weight: bold;
  }
  &__item:nth-last-child(1):hover {
    border-bottom: 0;
    color: $text-blue;
    font-weight: bold;
    transition: 0.2s;
  }
}
.footer-phones {
  display: flex;
  justify-content: center;
  margin-top: 18px;
  &__item {
    font-family: "Century Gothic", serif;
    font-size: 18px;
    margin-left: 100px;
    margin-right: 100px;
    letter-spacing: 1.2px;
    line-height: 20px;
    text-decoration: none;
    font-weight: bold;
    color: $white-color;
    text-transform: uppercase;
  }
}

@media(max-width: 1250px) {
  .footer-menu {
    display: flex;
    max-height: 115px;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    &__logo {
      position: relative;
    }
  }
  .footer-phones {
      padding-bottom: 30px;
    &__item {
      margin: 0 auto;
    }
  }
}
@media(max-width: 650px) {
  .footer-menu {
    display: none;
  }
  .footer-phones {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding-top: 20px;
    &__item {
      padding-top: 10px;
      margin: 0 auto;
    }
  }
}
</style>