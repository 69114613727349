<template>
  <section id="donate">
    <div class="donate">
      <div class="container">
        <div class="row">
          <Title tag="h4" :text="content.title" />
        </div>
        <div class="row donate__content">
          <div class="col-md-12 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
            <!-- LiqPay widget place -->
            <Liqpay />
          </div>
          <div class="col-md-12 col-lg-6 col-xl-6 col-sm-12 col-xs-12">
            <p class="donate__text">{{ content.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Title from "./Title";
import Liqpay from "./Liqpay";

export default {
  name: "Donate",
  components: { Title, Liqpay },
  props: {
    content: {
      title: "",
      text: "",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/colors";

#donate {
  min-height: 100vh;
  background-color: $white-color;
  @media (max-width: 1080px) {
    min-height: 0;
    padding-bottom: 80px;
  }
}

.donate {
  height: 100%;
  &__content {
    padding-top: 80px;
  }
  &__text {
    font-family: "Century Gothic", serif;
    font-size: 18px;
    line-height: 25px;
    color: $text-grey;
    padding-top: 20px;
    padding-left: 10px;
    text-align: left;
    @media (max-width: 500px) {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
    }
  }
  &__text-link:hover {
    color: $text-blue;
  }
}
</style>
