<template>
  <div class="general">
    <b-row>
      <Table :items="news" @onRowClick="itemClick($event)" />
    </b-row>
    <b-row>
      <b-input class="menu-item" type="text" placeholder="Title" v-model="item.title"/>
      <b-input class="menu-item" type="text" placeholder="Subtitle" v-model="item.subtitle"/>
      <p style="color: #110000;">Banner</p>
      <input type="file" @change="changeBanner">
      <p style="color: #110000;">Preview</p>
      <input type="file" @change="changePreview">
      <wysiwyg style="color: #110000;" v-model="item.content" />
    </b-row>
    <b-row>
      <b-button variant="success" @click="addNew" >Додати новину</b-button>
    </b-row>
  </div>
</template>

<script>
import {mFirestore, mStorage} from "../../main";
import Table from "../../components/admin/Table";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "News",
  components: {Table},
  data() {
    return {
      banner: null,
      preview: null,
      item: {
        title: '',
        subtitle: '',
        image: '',
        previewImage: '',
        date: '',
        content: ''
      }
    }
  },
  methods: {
    changeBanner(event) {
      this.banner = event.target.files[0]
    },
    changePreview(event) {
      this.preview = event.target.files[0]
    },
    addNew() {
      if(!this.banner || !this.preview) {
        return
      }
      mStorage.ref().child(`/news/${this.banner.name}_${new Date()}`).put(this.banner)
          .then( async resB => {
            mStorage.ref().child(`/news/${this.preview.name}_${new Date()}`).put(this.preview)
                .then( async resP => {
              await mFirestore.collection('news').add({
                ...this.item,
                date: new Date(),
                image: await resB.ref.getDownloadURL(),
                previewImage: await resP.ref.getDownloadURL(),
                uid: this.generateUid()
              }).then(() => {
                this.$toast.success('News successfully added!')
                this.item.content = ''
                this.item.title = ''
                this.item.date = null
                this.item.subtitle = ''
                this.item.image = null
                this.item.previewImage = null
              })
                  .catch(err => this.$toast.error(err))
            }).catch(err => this.$toast.error(err))
      }).catch(err => this.$toast.error(err))
    },
    itemClick(event) {
      return this.$router.push(`/administrator/news/${event}`)
    },
    generateUid() {
      return this.$uuid.v4()
    },
    ...mapActions({
      getNews: 'general/getNews'
    })
  },
  computed: {
    ...mapGetters({
      news: 'general/news'
    })
  },
  mounted() {
    this.getNews()
  }
}
</script>

<style scoped>

</style>