<template>
  <div class="sidebar" v-if="user">
    <b-sidebar id="sidebar" width="20%" no-slide no-close-on-esc no-close-on-route-change no-header-close  :title="user.email" bg-variant="dark" text-variant="light" :visible="showSidebar" >
      <div class="sidebar__menu">
        <router-link class="sidebar__item" to="/administrator" exact>Головна</router-link>
        <router-link class="sidebar__item" to="/administrator/about" exact>Про нас</router-link>
        <router-link class="sidebar__item" to="/administrator/news" exact>Новини</router-link>
        <router-link class="sidebar__item" to="/administrator/projects" exact>Проєкти</router-link>
        <router-link class="sidebar__item" to="/administrator/support" exact>Волонтерство</router-link>
        <router-link class="sidebar__item" to="/administrator/partnership" exact>Партнерство</router-link>
        <router-link class="sidebar__item" to="/administrator/video" exact>Відео</router-link>
        <router-link class="sidebar__item" to="/administrator/photo" exact>Фото</router-link>
        <router-link class="sidebar__item" to="/administrator/forms" exact>Заявки</router-link>
        <router-link class="sidebar__item" to="/administrator/profile" exact>Профіль</router-link>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import firebase from "firebase/app";

export default {
  name: "Menu",
  data() {
    return {
      user: firebase.auth().currentUser
    }
  },
  computed: {
    ...mapGetters({
      // user: 'admin/user',
      showSidebar: 'admin/showSidebar'
    })
  },
  mounted() {
    setTimeout(() => {
      this.user = firebase.auth().currentUser
    }, 500)
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 40px 20px;
  }
  &__item {
    color: #ffffff;
    font-size: 18px;
    text-decoration: none;
    margin-bottom: 5px;
  }
  &__item:hover {
    color: #00aae5;
  }
  .router-link-active {
    color: #00aae5;
  }
}
</style>