<template>
  <div class="profile">
    <b-form @submit.prevent="updateProfile" class="profile__form">
      <b-row>
        <b-col cols="6" class="profile__data">
          <h1 class="title">Профіль адмінісратора</h1>
          <b-input disabled class="profile__item" type="text" placeholder="E-mail" v-model="email"/>
          <b-input class="profile__item" type="text" placeholder="Old password" v-model="oldPassword"/>
          <b-input class="profile__item" type="text" placeholder="Password" v-model="password"/>
          <b-button style="margin-top: 50px;" type="submit" variant="success">Зберегти зміни</b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  name: "Profile",
  data() {
    return {
      email: '',
      oldPassword: '',
      password: ''
    }
  },
  methods: {
    updateProfile() {
     if(this.password && (this.password.length >= 6))
     {
       if(this.oldPassword && this.oldPassword.length)
       {
         this.user
             .reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(this.email, this.oldPassword))
             .then( res => {
               res.user.updatePassword(this.password)
               this.$toast.success("Profile successfully updated!")
             }).catch( err => this.$toast.error(err.message))
       } else {
         this.$toast.error("Old password filed can't be empty!")
       }
     } else {
       this.$toast.error("Password must be at least 6 characters!")
     }
    }
  },
  computed: {
    user() {
      return firebase.auth().currentUser
    }
  },
  mounted() {
    this.email = this.user.email
  }
}
</script>

<style scoped lang="scss">
.profile {
  &__form {
    margin: 80px;
  }
  &__item {
    margin-top: 12px;
  }
}
</style>