import { mFirestore } from '../../main'

export default {
    state: () => ({
        general: {},
        about: {},
        support: {},
        partnership: {},
        photos: [],
        videos: [],
        news: [],
        projects: [],
        newsItem: {},
        projectsItem: {},
        forms: []
    }),

    mutations: {
        setGeneral(state, data) {
            state.general = data
        },
        setAbout(state, data) {
            state.about = data
        },
        setSupport(state, data) {
            state.support = data
        },
        setPartnership(state, data) {
            state.partnership = data
        },
        setPhotos(state, data) {
            state.photos = data
        },
        setVideos(state, data) {
            state.videos = data
        },
        setNews(state, data) {
            state.news = data
        },
        setProjects(state, data) {
            state.projects = data
        },
        setNewsItem(state, data) {
            state.newsItem = data
        },
        setProjectsItem(state, data) {
            state.projectsItem = data
        },
        setForms(state, data) {
            state.forms = data
        }
    },

    actions: {
        getGeneral({commit}) {
          mFirestore.collection('general').get().then(querySnapshot => {
              querySnapshot.forEach(doc => commit('setGeneral', doc.data()))
          })
        },
        getAbout({commit}) {
            mFirestore.collection('about').get().then(querySnapshot => {
                querySnapshot.forEach(doc => commit('setAbout', doc.data()))
            })
        },
        getSupport({commit}) {
            mFirestore.collection('support').get().then(querySnapshot => {
                querySnapshot.forEach(doc => commit('setSupport', doc.data()))
            })
        },
        getPartnership({commit}) {
            mFirestore.collection('partnership').get().then(querySnapshot => {
                querySnapshot.forEach(doc => commit('setPartnership', doc.data()))
            })
        },
        getPhotos({commit}) {
            mFirestore.collection('photos').get().then(querySnapshot => {
                querySnapshot.forEach(doc => commit('setPhotos', doc.data()))
            })
        },
        getVideos({commit}) {
            mFirestore.collection('videos').get().then(querySnapshot => {
                querySnapshot.forEach(doc => commit('setVideos', doc.data()))
            })
        },
        getNews({commit}) {
            const news = []
            mFirestore.collection('news').orderBy("date", "desc").get()
                .then(querySnapshot => {
                querySnapshot.forEach(doc => news.push(doc.data()))
            })
            commit('setNews', news)
        },
        getNewsItem({commit}, uid) {
            mFirestore.collection('news').where("uid","==", uid).get().then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    commit('setNewsItem', doc.data())
                })
            })
        },
        getProjects({commit}) {
            const projects = []
            mFirestore.collection('projects').orderBy('date', 'desc').get()
                .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    projects.push(doc.data())
                })
            })
            commit('setProjects', projects)
        },
        getProjectsItem({commit}, uid) {
            mFirestore.collection('projects').where("uid","==", uid).get().then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    commit('setProjectsItem', doc.data())
                })
            })
        },
        async createForm(ctx, payload) {
            await mFirestore.collection('forms').add({
                ...payload,
            })
        },
        getForms({commit}) {
            const forms = []
            mFirestore.collection('forms').get().then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    forms.push(doc.data())
                })
            })
            commit('setForms', forms)
        }
    },

    getters: {
        general: s => s.general,
        about: s => s.about,
        support: s => s.support,
        partnership: s => s.partnership,
        photos: s => s.photos,
        videos: s => s.videos,
        news: s => s.news,
        projects: s => s.projects,
        newsItem: s => s.newsItem,
        projectsItem: s => s.projectsItem,
        forms: s => s.forms
    },

    namespaced: true
}