<template>
<main>
  <About :content-top="general.about.paragraph1"
         :content-main="general.about.paragraph2"
         :image="general.about.image"
         :titleText="general.about.title" />
  <Help :content="general.support"
        :shape="true" />
  <News :news="news.slice(0, 3)" :title="general.newsTitle"/>
  <Donate :content="general.donate" />
  <Contacts :content="general.contacts" />
</main>
</template>

<script>
import About from "../components/About";
import Help from "../components/Help";
import News from "../components/News";
import Contacts from "../components/Contacts";
import Donate from "../components/Donate";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Home",
  components: {
    News,
    About,
    Help,
    Donate,
    Contacts
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      general: 'general/general',
      news: 'general/news'
    })
  },
  methods: {
    ...mapActions({
      getNews: 'general/getNews'
    })
  },
  mounted() {
    this.getNews()
  }
}
</script>

<style scoped>

</style>