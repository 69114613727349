<template>
  <div class="title col-12">
    <component :is="tag" class="title__text">{{text}}</component>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    text: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/style/colors";

.title {
  display: flex;
  justify-content: center;
  padding-top: 45px;
  &__text {
    font-family: "Bad Script", serif;
    font-size: 30px;
    line-height: 68px;
    color: $text-blue;
    position: relative;
    text-align: center;
  }
  &__text:before {
    display: block;
    content: " ";
    border-bottom: 2px solid $text-blue;
    border-radius: 1px;
    top: 50%;
    width: 85px;
    @media(max-width: 350px) {
      width: 45px;
      left: -75px;
    }
    position: absolute;
    left: -120px;
  }
  &__text:after {
    display: block;
    content: " ";
    border-bottom: 2px solid $text-blue;
    border-radius: 1px;
    top: 50%;
    width: 85px;
    @media(max-width: 350px) {
      width: 45px;
      right: -75px;
    }
    position: absolute;
    right: -120px;
  }
}
</style>