<template>
  <main>
    <div id="videos" v-if="videos && videos.videos.length">
    <div class="videos container">
      <VueSlickCarousel class="videos__slider" v-bind="settings" v-if="videos.videos.length">
        <div class="videos__slide" v-for="(video, idx) in videos.videos" v-html="video" :key="idx">
        </div>
        <template #prevArrow>
          <div class="prevArrow"><SvgArrow /></div>
        </template>
        <template #nextArrow>
          <div class="nextArrow"><SvgArrow /></div>
        </template>
      </VueSlickCarousel>
    </div>
    </div>
  </main>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import SvgArrow from "../components/SvgArrow";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "VideosPage",
  components: {
    SvgArrow,
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        focusOnSelect: true,
        slidesToScroll: 1,
        // "focusOnSelect": true,
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        swipe: true,
        lazyLoad: 'progressive',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
            }
          },
          {
            breakpoint: 780,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions({
      getVideos: 'general/getVideos'
    })
  },
  computed: {
    ...mapGetters({
      videos: 'general/videos'
    })
  },
  async mounted() {
    await this.getVideos()
  }
}
</script>

<style lang="scss">
@import '../assets/style/colors';
#videos{
  @media(max-width: 1080px) {
    margin-top: 65px;
  }
}
  .videos {
    margin-top: 100px;
    margin-bottom: 100px;
    &__slider {
    }
    &__slide {
      display: flex!important;
      justify-content: center;
      padding-left: 35px;
      padding-right: 35px;
    }
  }
  .nextArrow, .prevArrow {
    background-color: $text-blue;
    width: 60px;
    height: 60px;
    border: 4px solid $white-color;
    border-radius: 50%;
    color: $white-color;
    padding: 12px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .prevArrow {
    position: absolute;
    top: calc(50% - 30px);
    left: 5px;
    z-index: 9999;
    transform: rotate(90deg);
  }
  .nextArrow {
    position: absolute;
    top: calc(50% - 30px);
    right: 5px;
    z-index: 9999;
    transform: rotate(-90deg);
  }
</style>