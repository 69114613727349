<template>
<section id="about">
  <div class="container">
    <div class="row">
      <Title tag="h1" :text="titleText" />
    </div>
    <div class="row about">
      <div class="col-md-6 col-xl-6 col-sm-12 col-xs-12">
        <p class="about__text">
          {{contentTop}}
        </p>
      </div>
      <div class="col-md-6 col-xl-6 col-sm-12 col-xs-12">
          <img class="about__image" :src="image" alt="" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
      <p class="about__text">
        {{contentMain}}
      </p>
      </div>
        </div>
  </div>
</section>
</template>

<script>
import Title from "./Title";
export default {
  name: "About",
  components: {Title},
  props: {
    contentTop: {
      type: String,
      required: true
    },
    contentMain: {
      type: String,
      required: true
    },
    titleText: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/style/colors';
  #about {
    min-height: 100vh;
    padding-bottom: 80px;
    @media(max-width: 1080px) {
      min-height: 0;
      margin-top: 65px;
    }
  }
  .about {
    padding-top: 45px;
    &__text {
      font-family: "Century Gothic", serif;
      font-size: 18px;
      line-height: 25px;
      color: $text-grey;
    }
    &__image {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
</style>