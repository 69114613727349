<template>
<section id="support">
  <div class="support">
    <img src="../assets/images/sign.png" alt="" class="support__sign">
    <h2 class="support__title">{{content.title}}</h2>
    <div class="support__bg" :style="shape ? 'clip-path:  polygon(50% 90%, 100% 100%, 100% 0, 0 0, 0 100%);' : ''">
    <div class="container">
      <div class="row support__inner">
        <div class="col-md-12 col-xl-6 col-sm-12 col-xs-12">
          <div class="support__heart">
<!--            <img src="../assets/images/heart.png" alt="" class="heart-image">-->
            <p class="heart-text" v-html="content.text"></p>
          </div>
        </div>
        <div class="col-md-12 col-xl-6 col-sm-12 col-xs-12">
          <div class="support__form">
            <form class="form" @submit.prevent="sendForm">
              <p class="form__title">{{content.formTitle}}</p>
              <p class="form__subtitle">{{content.formSubtitle}}</p>
              <div class="form__wrapper">
              <div class="form__group field">
                <input type="text" class="form__field" :placeholder="content.formLabels[0]" v-model="$v.name.$model" name="name" id='name' required />
                <label for="name" class="form__label">{{content.formLabels[0]}}</label>
              </div>
              <div class="form__group field">
                <masked-input mask="\+\3\8\(111)-111-11-11"
                        type="text" class="form__field"
                        :placeholder="content.formLabels[1]" v-model="$v.phone.$model" name="phone" id='phone' required />
                <label for="phone" class="form__label">{{content.formLabels[1]}}</label>
              </div>
                <input class="form__submit" type="submit" :value="content.submitText">
              </div>
            </form>
          </div>
        </div>
        </div>
      </div>
    </div>
    </div>
</section>
</template>

<script>
import MaskedInput from 'vue-masked-input'
import required from "vuelidate/lib/validators/required";
import {mapActions} from "vuex";

export default {
  name: "Help",
  components: {
    MaskedInput
  },
  props: {
    shape: {
      type: Boolean
    },
    content: {
      type: Object,
      required: true,
    }
  },
  validations: {
    name: {
      required
    },
    phone: {
      required
    }
  },
  data() {
    return {
      name: '',
      phone: ''
    }
  },
  methods: {
    ...mapActions({
      createForm: 'general/createForm'
    }),
    sendForm() {
      this.$v.$touch()
      if(this.$v.$invalid) {
        this.$toast.error('Invalid credentials')
      } else {
        this.createForm({name: this.name, phone: this.phone, uid: this.generateUid(), date: new Date()}).then(() => {
          this.$toast.success('Дякуємо за Вашу небайдужість. Найближчим часом ми з Вами звяжемось!')
        })
      }
    },
    generateUid() {
      return this.$uuid.v4()
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/style/colors';

#support {
  min-height: 100vh;
  @media(max-width: 1080px) {
    min-height: 0;
  }
}
.support {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  &__title {
    font-family: "Bad Script", serif;
    font-weight: normal;
    font-size: 30px;
    line-height: 68px;
    text-align: center;
    color: $white-color;
    z-index: 1000;
    position: absolute;
    left: calc(50% - 95px);
    top: 0;
    @media(max-width: 650px) {
      line-height: 34px;
      font-size: 20px;
      left: calc(50% - 65px);
    }
  }
  &__heart {
    position: relative;
    min-height: 100%;
    min-width: 100%;
    background: url(../assets/images/heart.png) no-repeat center;
    display: flex;
    justify-content: center;
    padding-top: 260px;
    @media(max-width: 1120px){
      margin-top: 100px;
      padding-top: 100px;
      padding-bottom: 100px;
      background-size: contain;
    }
    @media(max-width: 650px) {
      margin-top: 80px;
      padding-top: 40px;
      padding-bottom: 0;
    }
  }
  &__form {
    margin-bottom: 160px;
  }
  &__sign {
    position: absolute;
    z-index: 1000;
    top: -11.3px;
    max-width: 280px;
    left: calc(50% - 140px);
    @media(max-width: 650px) {
      max-width: 160px;
      left: calc(50% - 80px);
      top: -6.3px;
    }
  }
  &__bg {
    background: $grey-bg;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    @media(max-width: 1080px) {
      min-height: 0;
    }
  }
}
.form {
  position: relative;
  min-height: 100%;
  padding-top: 150px;
  padding-left: 90px;
  padding-right: 90px;
  @media(max-width: 650px) {
    padding: 0;
    margin-top: 100px;
  }
  &__title {
    font-family: "Century Gothic", serif;
    font-weight: bold;
    font-size: 34px;
    line-height: 34px;
    letter-spacing: 1.6px;
    color: $white-color;
    text-align: center;
    padding: 0;
    margin-top: 60px;

  }
  &__subtitle {
    font-family: "Century Gothic", serif;
    font-size: 15px;
    line-height: 16px;
    color: $white-color;
    letter-spacing: 0.4px;
    font-style: italic;
    text-align: center;
    @media(max-width: 650px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &__wrapper {
    padding-top: 40px;
    padding-right: 45px;
    padding-left: 45px;
  }

  &__submit {
    width: 260px;
    height: 50px;
    background-color: $text-blue;
    border: none;
    color: $white-color;
    font-family: "Century Gothic", serif;
    font-size: 18px;
    line-height: 25px;
    margin-left: calc(50% - 130px);
    margin-top: 90px;
    -webkit-box-shadow: 0 7px 10px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 7px 10px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 7px 10px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  &__submit:hover {
    transform: translateY(-5px);
    transition: 0.3s;
  }
}
.heart-image {
  position: relative;
  padding-top: 110px;
  max-width: 475px;
}
.heart-text {
  font-family: "Century Gothic", serif;
  font-size: 18px;
  line-height: 25px;
  color: $white-color;
  text-align: center;
  max-width: 360px;
  @media(max-width: 650px) {
    font-size: 16px;
    line-height: 20px;
    max-width: 240px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media(max-width: 410px) {

    font-size: 14px;
    line-height: 16px;
    max-width: 180px;
  }
}
.form__group {
  position: relative;
  margin-top: 10px;
  width: 100%;
  padding: 20px 0 0;
}

.form__field {
  font-family: "Century Gothic", serif;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $white-color;
  outline: 0;
  font-size: 16px;
  line-height: 20px;
  color: $white-color;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 16px;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  font-family: "Century Gothic", serif;
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 16px;
  color: $white-color;
}

.form__field:focus {
  ~ .form__label {
    font-family: "Century Gothic", serif;
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 16px;
    color: $text-blue;
    font-weight:500;
  }
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-color: $text-blue;
  border-image-slice: 1;
}
/* reset input */
.form__field{
  &:required,&:invalid { box-shadow:none; }
}
</style>